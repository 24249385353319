<template>
  <div
    ref="containerRef"
    :class="['container', variant]"
    :style="{
      '--duplicates-count': duplicatesCount,
      '--pixels-scroll-per-second': scrollSpeed,
      '--direction': direction,
    }"
  >
    <div ref="marqueeTextRef" class="sample">{{ text }}</div>
    <div class="marquee-text" v-html="content"></div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  direction: {
    type: String,
    required: true,
  },
  scrollSpeed: {
    type: Number,
    required: false,
    default: 0,
  },
  variant: {
    type: String,
    default: null,
  },
});

const textDividerIcon = `<svg width="19" height="30" viewBox="0 0 19 30" fill="none" xmlns="http://www.w3.org/2000/svg" style="height: 100%;">
<path d="M18.2544 26.5194H16.5628C17.853 25.1671 19.0022 23.1721 18.9998 20.3648C18.995 15.1803 15.9966 14.9653 16.9451 11.2405C16.9618 11.1736 16.9069 11.0828 16.84 11.1043C14.6777 11.7853 13.8774 14.4588 13.7269 14.4588C13.9873 10.4043 13.3924 5.34399 8.47063 0.620563C8.39657 0.548887 8.27472 0.620563 8.29861 0.720909C9.72257 6.69627 7.58663 10.605 6.16745 11.3217C5.40052 8.48817 3.27653 7.71646 2.65295 7.7523C2.59083 7.75708 2.55261 7.81919 2.56933 7.87892C3.91445 12.5498 0.0869619 14.5854 0.0917403 19.875C0.0941295 22.9834 1.25289 25.1122 2.55022 26.5194H0.837168C0.717708 26.5194 0.622141 26.615 0.622141 26.7344V28.8226C0.622141 28.942 0.717708 29.0376 0.837168 29.0376H6.5999L6.45893 28.9134C3.53456 26.0296 3.12601 20.059 8.11225 16.2817C8.1911 16.222 8.301 16.2889 8.28428 16.3868C7.65114 19.7221 8.21021 21.87 9.8253 23.6906C11.1107 22.4625 12.181 20.0925 12.4367 19.4952C12.4654 19.4283 12.5538 19.4092 12.6087 19.4593C16.0898 22.7039 14.231 27.4512 13.0316 28.9157L12.9002 29.04H18.252C18.3714 29.04 18.467 28.9444 18.467 28.825V26.7368C18.467 26.6173 18.3714 26.5218 18.252 26.5218" fill="#FF6A39"/>
</svg>`;

const containerRef = ref<HTMLElement | null>(null);
const marqueeTextRef = ref<HTMLElement | null>(null);
const content = ref(props.text);
const duplicatesCount = ref<number>(0);

const direction = computed(() => (props.direction === 'ltr' ? 'normal' : 'reverse'));

function updateTicker() {
  if (!containerRef.value || !marqueeTextRef.value) {
    return;
  }

  const containerClientRect = containerRef.value.getBoundingClientRect();
  const textClientRect = marqueeTextRef.value.getBoundingClientRect();
  const n = Math.ceil(containerClientRect.width / textClientRect.width) * 2;

  content.value = [...Array(n)].map(() => props.text).join(props.variant === 'variant-2' ? textDividerIcon : ' ');
  duplicatesCount.value = n;
}

onMounted(() => {
  window.addEventListener('resize', updateTicker);
  updateTicker();
});

onUnmounted(() => {
  window.removeEventListener('resize', updateTicker);
});
</script>

<style lang="scss" scoped>
.container {
  @include local-mixins.ff-hlp-93;
  display: block;
  overflow: hidden;
  user-select: none;
  background: #000;
  color: #fff;
  letter-spacing: -0.01em;

  &.variant-1 {
    font-size: 1.662rem;
    padding-block: 1rem;
    background: #fe6a38;

    @include local-mixins.tablet {
      font-size: 4rem;
      padding-block: 1.5rem;
    }

    @include local-mixins.desktop {
      font-size: 5.5rem;
      padding-block: 2rem;
    }
  }

  &.variant-2 {
    font-size: 0.75rem;
    padding-block: 0.5rem;
    background: #1e1e1e;
    transform: rotate(1deg);

    @include local-mixins.desktop_and_tablet {
      padding-block: 1rem;
      font-size: 2.5rem;
    }
  }
}

.sample {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.marquee-text {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  gap: 1rem;
  animation: scroll calc((10 * var(--duplicates-count)) * 1s) linear infinite;
  animation-direction: var(--direction);
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}
</style>
