<template>
  <div
    v-if="product && layout != 'full-size'"
    :class="[
      {
        'product-card-container': !renderInCartModal,
        'product-card-container-cart-modal': renderInCartModal,
        'search-page': renderSearch,
        'plp-page': renderPlp,
        'search-dropdown-height': dropdownSearchHeight,
      },
    ]"
  >
    <NuxtLink
      class="product-card"
      :to="localePath(productUrl)"
      :custom="!pdpLinkEnabled"
      @click="linkClickEmitter($event)"
    >
      <div :class="['product-tag ff-display-600 uc', { 'no-tag': !tag }]" v-text="tag" />
      <div class="image-wrapper">
        <Video
          v-if="checkIfVideo(imagesSwap[0])"
          :preload="true"
          :asset="imagesSwap[0]"
          :options="{ width: state.imgWidth, height: state.imgHeight, quality: state.quality }"
          :display_as_gif="true"
          :video-classes-override="['image', { 'a2c-pinned-img': hideImageAtc }]"
        />
        <CloudinaryImage
          v-else
          :public-id="imagePublicId"
          :hover-public-id="hoverImagePublicId"
          :options="{ width: state.imgWidth, height: state.imgHeight, quality: state.quality }"
          :alt="title"
          :image-classes-override="['image', { 'a2c-pinned-img': hideImageAtc }]"
          class="flex cntr"
        />
      </div>

      <div class="product-details">
        <h3 class="product-title h4 ff-text-500" v-text="title" />

        <YotpoStarRating v-if="productId && isDynamicSection" :product-id="productId" :yotpo-key="yotpoKey" />
        <!-- Prevent card nav clicking -->
        <PriceSection :sku="selectedVariant?.bc_variant?.sku || ''" :is-pdp="false" @click.prevent />

        <div
          v-if="show_short_desc && selectedVariant?.short_description"
          class="additional-info mgn-btm-1 desc"
          v-html="selectedVariant.short_description"
        />
        <div v-if="show_whats_included && selectedVariant?.whats_included" class="additional-info mgn-btm-1 includes">
          Includes: {{ selectedVariant.whats_included }}
        </div>
      </div>
    </NuxtLink>

    <div v-if="maxInCart" class="add-to-cart-error">Max Quantity Reached</div>
    <div class="product-card-actions">
      <ProductOptions
        v-show="shouldOptionsShow"
        :sku="baseProduct?.sku"
        :option-default-override-sku="defaultVariantSku"
        :product-variations="productVariations"
        @product-options:update="updateOption"
        @modifiers-changed="setProductModifiers"
      />
      <span v-if="backorderMessage" class="backorder-message">({{ backorderMessage }})</span>
      <div v-if="show_add_to_cart" class="flex-inline product-card-buttons">
        <AddToCartButton
          style="margin-top: auto"
          :product="cartProduct"
          :variant="state.bcVariant"
          :disable-btn="maxInCart || bcVariantDisabled || selectedVariant?.variant_disabled"
          :is-variant="hasOptions"
          :quantity="quantity"
          :images="imagesSwap"
          :is-small="true"
          :secondary-button="isCartPage || !!isCartModal"
          @added-to-cart="addedEvent"
        />
        <CustomButton
          v-if="hasEtchingAvailable"
          :to="localePath(customUrl)"
          class="mgn-l-pt5"
          :is-small="true"
          :aria-attrs="{
            'aria-label': `Personalize ${title}`,
          }"
          style-name="secondary"
          style-color="black"
          @click-button="linkClickEmitter($event)"
          >Personalize</CustomButton
        >
      </div>
    </div>
  </div>

  <!-- layout for full-width block -->
  <div
    v-else
    ref="compRef"
    :class="[
      'product-card-container',
      {
        'search-page': renderSearch,
        'plp-page': renderPlp,
        'search-dropdown-height': dropdownSearchHeight,
        'full-width': layout,
      },
    ]"
  >
    <div class="product-card">
      <!-- <div :class="['product-tag ff-display uc', { 'no-tag': !tag }]" v-text="tag" /> -->
      <div class="product-card-images">
        <div v-for="i in 3" :key="'product-image-' + i" class="image-wrapper" :data-id="i">
          <NuxtLink
            class="product-card"
            :to="localePath(productUrl)"
            :custom="!pdpLinkEnabled"
            @click="linkClickEmitter($event)"
          >
            <Video
              v-if="checkIfVideo(imagesSwap[i])"
              :preload="true"
              :asset="imagesSwap[i]"
              :options="getFullsizeImageOptions(i) as VideoOptions"
              :display_as_gif="true"
              :video-classes-override="['small-image']"
            />
            <CloudinaryImage
              v-else
              :key="imagesSwap[i]?.public_id"
              :public-id="imagesSwap[i]?.public_id"
              :options="getFullsizeImageOptions(i) as ImageOptions"
              :alt="title"
              :image-classes-override="['image', { 'a2c-pinned-img': hideImageAtc }]"
              class="flex cntr"
            />
          </NuxtLink>
        </div>
      </div>

      <div class="product-details">
        <h3 class="product-title h4 ff-text-500" v-text="title" />
        <div
          v-if="show_short_desc && selectedVariant?.short_description"
          class="additional-info mgn-btm-1 desc"
          v-html="selectedVariant.short_description"
        />

        <!-- Prevent card nav clicking -->
        <PriceSection :sku="selectedVariant?.bc_variant?.sku || ''" :is-pdp="false" @click.prevent />

        <div v-if="show_whats_included && selectedVariant?.whats_included" class="additional-info mgn-btm-1 includes">
          Includes: {{ selectedVariant.whats_included }}
        </div>

        <ProductOptions
          v-show="shouldOptionsShow"
          :sku="baseProduct?.sku"
          :option-default-override-sku="defaultVariantSku"
          :product-variations="productVariations"
          @product-options:update="updateOption"
          @modifiers-changed="setProductModifiers"
        />
        <span v-if="backorderMessage" class="backorder-message">({{ backorderMessage }})</span>

        <div v-if="maxInCart" class="add-to-cart-error">Max Quantity Reached</div>
        <div class="flex-inline atc-buttons">
          <div
            v-if="shopifyAddToCartBtnContainerId && shopifyA2CBtn"
            :id="shopifyAddToCartBtnContainerId"
            class="sbbtn"
          ></div>
          <ShopifyBuyButton
            v-if="shopifyAddToCartBtnContainerId && shopifyA2CBtn"
            :container-id="shopifyAddToCartBtnContainerId"
            :shopify-buy-btn="shopifyA2CBtn.shopify_buy_button"
            button-destination="checkout"
            :btn-text="shopifyA2CBtn.button_text"
            btn-class="btn btn-small btn-primary btn-color-black"
          />
          <AddToCartButton
            v-if="show_add_to_cart && shopifyA2CBtn"
            style="margin-top: auto"
            :product="cartProduct"
            :variant="state.bcVariant"
            :disable-btn="maxInCart || bcVariantDisabled"
            :is-variant="hasOptions"
            :quantity="quantity"
            :images="imagesSwap"
            :is-small="true"
            :secondary-button="isCartPage"
            @added-to-cart="addedEvent"
          />
          <CustomButton
            v-for="(button, bi) in mappedButtons"
            v-show="!button?.shopify_buy_button?.enabled"
            :ref="setButtonEl"
            v-bind="button"
            :key="button.button_text + bi"
            :to="button.to"
            :href="button.href"
            class="cta"
            :style-name="button?.type"
            :style-color="button?.style_color"
            @click-button="button?.isHashLink ? scrollToElementById(button.href as string, $event) : {}"
          >
            {{ button.button_text }}
          </CustomButton>
          <template v-if="buttonEls.length && shopifyBuyBtnContainersId !== '-'">
            <div
              v-for="(button, bi) in filteredShopifyButtons"
              :id="'sbc' + button.button_text + shopifyBuyBtnContainersId + bi"
              :key="'sbc' + button.button_text + shopifyBuyBtnContainersId + bi"
              class="sbbtn"
            ></div>
            <ShopifyBuyButton
              v-for="(button, bi) in filteredShopifyButtons"
              :key="'sb' + button.button_text + shopifyBuyBtnContainersId + bi"
              :container-id="'sbc' + button.button_text + shopifyBuyBtnContainersId + bi"
              :shopify-buy-btn="button.shopify_buy_button"
              :btn-class="buttonEls?.[bi + 1]?.computedClass.join(' ').replace(/\s+/g, ' ').trim()"
              :btn-text="button.button_text"
            />
          </template>
        </div>
        <!-- @todo fetch spec details -->
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ProductV2 } from '@solo-stove/types/contentstack/stove/product';
import { CloudinaryAsset } from '@solo-stove/types/contentstack/stove/cloudinary';
import { ButtonCS } from '../types/contentstack';
import { GtmEventType, mapProductCardsItemsToGtmEvent } from '~/util/analytics';
import { hasAncestorWithClass } from '~/util/eventHandler';
import { maxCartProducts, getBackOrderMessage } from '~/util/bigcommerce';
import { useLocaleStore } from '~/store/locale';
import { useCartStore } from '~/store/cart';
import { useRootStore } from '~/store';
import { getAssetPublicId, mapMenuItem } from '~/util/contentstack/csHelpers';
import { generateIdNoDate } from '~/util/generateId';
import { useDeviceStore } from '~/store/device';

const deviceStore = useDeviceStore();
const localePath = useLocalePath();
const route = useRoute();
const localeStore = useLocaleStore();
const cartStore = useCartStore();
const rootStore = useRootStore();
const yotpoKey = rootStore.yotpoAppKey;

const emit = defineEmits(['added', 'linkClick', 'closer']);
type PCLinkClickEmit = 'linkClick' | 'closer';
type PCAddedEmit = 'added';

const props = defineProps({
  emitLinkClick: {
    type: String as () => PCLinkClickEmit,
    default: undefined,
  },
  emitAdded: {
    type: String as () => PCAddedEmit,
    required: false,
    default: undefined,
  },
  product: {
    type: Object as () => ProductV2,
    required: true,
  },
  isDynamicSection: {
    type: Boolean,
    default: false,
    required: false,
  },
  queryID: {
    type: String,
    default: undefined,
  },
  renderMode: {
    type: String,
    default: '',
  },
  index: {
    type: Number,
    default: undefined,
  },
  layout: {
    type: String,
    default: undefined,
  },
  /* eslint-disable vue/prop-name-casing */
  show_add_to_cart: {
    type: Boolean,
    default: false,
  },
  show_short_desc: {
    type: Boolean,
    default: false,
  },
  show_whats_included: {
    type: Boolean,
    default: false,
  },
  link_to_pdp: {
    type: String as () => 'enabled' | 'disabled',
    default: 'enabled',
  },
  defaultVariantSku: {
    type: String,
    default: undefined,
  },
  secondary_button_text: {
    type: String,
    default: 'Learn More',
  },
  buttons: {
    type: Array as () => ButtonCS[],
    required: false,
    default: null,
  },
  rowSize: {
    type: Number,
    default: 4,
  },
  /* eslint-enable vue/prop-name-casing */
});

const state = reactive({
  imgWidth: deviceStore.isMobile ? 196 : deviceStore.isTablet ? 270 : props.rowSize === 2 ? 550 : 304,
  imgHeight: deviceStore.isMobile ? 196 : deviceStore.isTablet ? 270 : props.rowSize === 2 ? 550 : 304,
  quality: 'auto',
  bcVariant: props.product?.product_offerings?.product_variations?.[0]?.bc_variant,
  productModifiers: [] as any[],
});

const backorderMessage = computed<string>(() => getBackOrderMessage(props.product, state.bcVariant.sku || '') || '');
const compRef = ref(null);

const shopifyA2CBtn = computed<any>(() => (props?.buttons || []).find((button) => button.shopify_buy_button?.enabled));
const shopifyAddToCartBtnContainerId = computed<any>(() =>
  compRef.value && shopifyA2CBtn.value?.shopify_buy_button?.enabled ? generateIdNoDate('sbproduct') : undefined
);
const buttonEls = reactive([]);
let buttonsSkipped = 0;
function setButtonEl(el) {
  if (props.buttons && props.buttons[buttonEls.length + buttonsSkipped]?.shopify_buy_button?.enabled) {
    buttonEls.push(el);
  } else {
    buttonsSkipped++;
  }
}
const mappedButtons = computed(() => {
  return props?.buttons?.map((button) => {
    return {
      ...button,
      ...mapMenuItem(button),
    };
  });
});
const filteredShopifyButtons = computed(() => {
  return props?.buttons
    ?.filter((button) => {
      return button.shopify_buy_button?.enabled;
    })
    .slice(1);
});
const shopifyBuyBtnContainersId = computed<any>(() =>
  compRef.value && filteredShopifyButtons.value?.length ? generateIdNoDate('sbpc') : '-'
);

const bcVariantDisabled = computed<boolean>(() => {
  return selectedVariant.value?.bc_variant?.purchasing_disabled || false;
});
const dropdownSearchHeight = computed<boolean>(() => {
  return props.renderMode === 'dropdown-search' && props.show_add_to_cart;
});
const renderSearch = computed<boolean>(() => {
  return props.renderMode === 'search';
});
const renderPlp = computed<boolean>(() => {
  return props.renderMode === 'plp';
});
// const renderClp = computed<boolean>(() => {
//   return props.renderMode === 'clp';
// });
const renderInCartModal = computed<boolean>(() => {
  return props.renderMode === 'cartModal';
});

const hideImageAtc = computed<boolean>(() => {
  return !['dropdown-search'].includes(props.renderMode);
});
const hasEtchingAvailable = computed<boolean>(() => {
  return props?.product?.etching_section?.enable_etching || false;
});
const pdpLinkEnabled = computed<boolean>(() => {
  return props.link_to_pdp === 'enabled';
});
const productDetails = computed(() => {
  return props.product?.product_details;
});

const productVariations = computed(() => {
  return props.product?.product_offerings?.product_variations;
});

const options = computed(() => {
  const variations = productVariations?.value;
  return variations?.length ? variations?.map((option: any) => option?.bc_variant) : [];
});

const cartProduct = computed<any>(() => {
  return {
    ...baseProduct?.value,
    ...(state.productModifiers?.length > 0 && { variantId: selectedVariant?.value?.bc_variant?.id }),
  };
});
const hasOptions = computed<boolean>(() => {
  return !!options?.value?.length;
});
const optionIndex = computed<number>(() => {
  let locatedIndex = 0;
  options.value.find((option: any, i: number) => {
    if (option?.id === state.bcVariant?.id) {
      locatedIndex = i;
      return true;
    }
    return false;
  });
  return locatedIndex;
});
const selectedVariant = computed(() => {
  const productVariations = props?.product?.product_offerings?.product_variations;
  return productVariations && productVariations[optionIndex.value] ? productVariations[optionIndex.value] : null;
});
const imagesSwap = computed<CloudinaryAsset[]>(() => {
  const newImages: Array<any> = selectedVariant.value
    ? (selectedVariant.value?.thumb?.length && selectedVariant.value?.thumb) || []
    : [];
  return newImages.concat(props.product?.product_offerings?.base_image_set);
});
const imagePublicId = computed(() => imagesSwap.value?.[0]?.public_id);
const hoverImagePublicId = computed(() => {
  return selectedVariant?.value?.thumb_hover ? getAssetPublicId(selectedVariant?.value?.thumb_hover) : undefined;
});
const tag = computed<string | undefined>(() => {
  return props.product?.product_taxonomy?.tag;
});
const title = computed(() => {
  return selectedVariant?.value?.display_title || baseProduct?.value?.name;
  // return baseProduct?.value?.name;
});
const baseProduct = computed(() => {
  return props.product?.product_offerings?.bc_primary_product?.product;
});
const productId = computed<number | undefined>(() => {
  return props.isDynamicSection ? baseProduct.value?.id : selectedVariant?.value?.bc_variant?.id;
});
const productUrl = computed<any>(() => {
  return {
    path: props.product?.url,
    query: {
      ...(state.bcVariant?.sku && { sku: state.bcVariant.sku }),
      ...(props.queryID && { queryID: props.queryID }),
    },
  };
});
const customUrl = computed<any>(() => {
  return {
    path: props.product?.url,
    query: {
      ...(state.bcVariant?.sku && { sku: state.bcVariant.sku }),
      ...(hasEtchingAvailable?.value && { customizeOpen: true }),
      ...(props.queryID && { queryID: props.queryID }),
    },
  };
});
const quantity = computed<number>(() => {
  return productDetails?.value?.min_quantity || 1;
});
const shouldOptionsShow = computed<boolean>(() => {
  const approvedOptionPlacements = ['clp', 'home', 'plp', 'search', 'pdp', 'splash-page', 'blog'];
  const dropdownProductOptionsEnabled = rootStore.algoliaSettings?.dropdown_product_options || false;
  if (dropdownProductOptionsEnabled) approvedOptionPlacements.push('dropdown-search');
  const renderModeChecker = approvedOptionPlacements.includes(props.renderMode);
  return hasOptions?.value && renderModeChecker;
});
const isCartPage = computed<boolean>(() => {
  const path = route.path?.toLowerCase();
  return path === '/' + localeStore.langCode + '/cart';
});
const isCartModal = inject<boolean>('isCartModal', false);
const maxInCart = computed<boolean>(() => {
  return maxCartProducts(cartStore.products, baseProduct?.value, quantity.value, productDetails?.value?.max_quantity);
});

function addedEvent() {
  if (props.emitAdded) {
    emit(props.emitAdded);
  }
}
function linkClickEmitter(event: MouseEvent) {
  const target = event.target as HTMLElement;
  const hasAncestor = hasAncestorWithClass(target.parentElement, 'options-container');
  // only track if not selecting variants/a2c
  if (!hasAncestor || target.classList.contains('product-card')) {
    const product = {
      product: props.product?.product_offerings?.bc_primary_product?.product,
      variant: selectedVariant.value?.bc_variant,
      hasVariants: hasOptions.value,
      index: props.index,
      quantity: quantity.value,
    };
    const gtm = useGtm();
    gtm?.trackEvent(mapProductCardsItemsToGtmEvent(GtmEventType.selectItem, [product as any]));
  }

  if (props.emitLinkClick) emit(props.emitLinkClick);
}

function updateOption(updatedVariant: any) {
  state.bcVariant = updatedVariant;
}
function setProductModifiers(modData: any) {
  state.productModifiers = modData as any;
}
function checkIfVideo(image: CloudinaryAsset): boolean {
  return image?.resource_type === 'video';
}
function getFullsizeImageOptions(i: number) {
  const firstImageDimentions = {
    width: deviceStore.isMobile ? 300 : deviceStore.isTablet ? 304 : 424,
    height: deviceStore.isMobile ? 300 : deviceStore.isTablet ? 520 : 'auto',
  };
  const secondImageDimentions = {
    width: deviceStore.isMobile ? 300 : deviceStore.isTablet ? 250 : 250,
    height: deviceStore.isMobile ? 300 : deviceStore.isTablet ? 250 : 250,
  };
  return {
    width: i === 1 ? firstImageDimentions.width : secondImageDimentions.width,
    height: i === 1 ? undefined : secondImageDimentions.height,
    quality: 'auto',
  };
}
</script>

<style lang="scss">
.product-card {
  .additional-info,
  .additional-info p,
  .additional-info a {
    font-size: 0.75rem;
    line-height: 150%;
    max-width: fit-content;
  }
}
</style>

<style lang="scss" scoped>
.product-card-container {
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: -webkit-fill-available;
  &.search-page,
  &.plp-page,
  &.search-dropdown-height {
    height: 100%;
  }
  .add-to-cart-error {
    color: $color-danger-default;
    width: 100%;
    padding: 0.625rem 0;
  }

  .product-card {
    color: $color-neutral-cool-900;
    position: relative;
    .product-title {
      color: $color-neutral-cool-900;
      @include local-mixins.tablet_and_mobile {
        font-size: 1rem;
      }
    }
    .product-details {
      margin-top: 8px;
      @include local-mixins.desktop {
        margin-top: 12px;
      }
    }
    .product-tag {
      position: absolute;
      width: fit-content;
      padding: 2px 8px;
      top: 0.5rem;
      left: 0.5rem;
      background-color: $color-neutral-white;
      font-size: 0.625rem;
      line-height: 150%;
      border-radius: 2px;
      &.no-tag {
        height: 20px;
        background-color: transparent;
      }
    }
    :deep(.price-container) {
      .current-price,
      .orig-price,
      .orig-price span {
        font-size: 0.875rem;
        line-height: 150%;
      }
    }
    :deep(.options-container) {
      .selected-title {
        display: none;
      }
      .color-swatch {
        height: 20px;
        width: 20px;
      }
      .variant-wrapper {
        width: 100%;

        .option {
          padding: 8px;
          font-size: 0.75rem;
        }
        &.opt-button:not(.selected) {
          border: $color-neutral-cool-200 1px solid;
          border-radius: 3px;
          &:hover,
          &:focus {
            border: $color-neutral-black 1px solid;
          }
        }
        &.selected {
          border: $color-primary-500 2px solid;
          border-radius: 3px;
        }
        .select-expanded {
          @include local-mixins.tablet_and_mobile {
            min-width: revert;
            width: 100%;
          }
        }
      }
    }
    :deep(.image-wrapper) {
      overflow: hidden;
      border-radius: 4px;
    }
    :deep(.image) {
      background-color: $color-neutral-cool-50;
      transition: transform 0.2s;
      height: auto;
      width: 100%;
      border-radius: 4px;
      &:hover {
        transform: scale(1.1);
      }
      position: relative;
      // TODO - check commenting out this is okay
      // &.a2c-pinned-img {
      //   z-index: -1;
      // }
    }
  }
  :deep(.options-container) {
    .selected-title {
      display: none;
    }
    .color-swatch {
      height: 20px;
      width: 20px;
    }
    .variant-wrapper {
      width: 100%;

      .option {
        padding: 8px;
        font-size: 0.75rem;
      }
      &.opt-button:not(.selected) {
        border: $color-neutral-cool-200 1px solid;
        border-radius: 3px;
        &:hover,
        &:focus {
          border: $color-neutral-black 1px solid;
        }
      }
      &.selected {
        border: $color-primary-500 2px solid;
        border-radius: 3px;
      }
      .select-expanded {
        @include local-mixins.tablet_and_mobile {
          min-width: revert;
          width: 100%;
        }
      }
    }
  }
  &.full-width {
    .product-card {
      display: flex;
      flex-wrap: wrap;
      @include local-mixins.desktop_and_tablet {
        flex-wrap: nowrap;
        justify-content: space-between;
      }
      .product-card-images {
        overflow: scroll;
        white-space: nowrap;
        @include local-mixins.mobile {
          margin-left: -12px;
          margin-right: -24px;
        }
        @include local-mixins.desktop_and_tablet {
          order: 2;
          min-height: 400px;
          display: grid;
          gap: 15px 14%;
          width: 65%;
          > :first-child {
            grid-row-end: span 2;
          }
          a,
          .cloudinary-image-container {
            height: 100%;
            img {
              object-fit: contain;
            }
          }
        }
        @include local-mixins.desktop {
          grid-template-columns: 54% 32%;
        }
        @include local-mixins.tablet {
          grid-template-columns: 63% 37%;
          gap: 15px;
        }
        .image-wrapper {
          display: inline-block;
          @include local-mixins.mobile {
            margin: 0 12px;
          }
          .cloudinary-image-container {
            width: 300px;
            &:hover .image {
              transform: none;
            }
            @include local-mixins.desktop_and_tablet {
              width: 100%;
            }
          }
        }
      }
      .product-details {
        @include local-mixins.desktop_and_tablet {
          width: 30%;
        }
        .atc-buttons {
          gap: 20px;
        }
      }
      .product-title {
        font-size: 2rem;
      }
      :deep(.additional-info p) {
        font-size: 1rem;
      }
      :deep(.price-container) {
        .current-price,
        .orig-price span {
          font-size: 1.5rem;
        }
      }
      :deep(.add-to-cart-wrapper),
      :deep(.btn-small) {
        width: 100%;
        max-width: 135px;
      }
    }
  }
}
.sbbtn {
  width: 100%;
  max-width: 135px;
}
.backorder-message {
  color: $color-primary-600;
}
.product-details:deep() {
  @include local-mixins.mobile {
    & .yotpo-sr-bottom-line-right-panel div {
      font-size: 12px !important;
    }
    & .star-container {
      width: 11px !important;
      height: 11px !important;
    }
  }
}
</style>
