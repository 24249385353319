<template>
  <div class="price-container flex-col">
    <div class="flex-inline">
      <div v-if="currentPrice" :class="['current-price', { 'is-hlp-carousel-current-price': isHlpCarousel }]">
        {{ localeStore.currencySymbol + currentPrice }}
      </div>
      <div v-if="pricesDiff" class="orig-price">
        <span class="strikethrough">{{ localeStore.currencySymbol + originalPrice }}</span>
        <span v-if="!localeStore?.isUsLocale && isPdp" class="msrp-price"> (MSRP)</span>
      </div>
      <div v-if="showInfoIcon && pricesDiff && !isHlpCarousel" ref="info" class="tooltip-container">
        <button class="tooltip-info-button" aria-label="Toggle Info Modal" @click="toggleTooltip">
          <InformationLine />
        </button>
        <div class="tooltip-popup" :class="{ open: tooltipOpen }">
          <div class="tooltip-copy">
            <CloseLine title="close modal" class="tooltip-close" @click="toggleTooltip" />
            <p class="heading h4">Comparable Value</p>
            <p>
              {{ useTranslate('PDP.priceDisclaimer') }}
            </p>
          </div>
          <div class="tooltip-arrow" />
        </div>
      </div>
    </div>
    <span v-if="priceDiff && !isHlpCarousel" class="savings-cta">{{
      useTranslate('GLOBALS.youSave') + ' ' + localeStore.currencySymbol + priceDiff
    }}</span>
    <template v-if="showPaymentOptions">
      <PayPalPayLater :value="currentPrice * quantity" placement="product" />
      <klarna-placement
        v-if="roundedCurrentPrice && !localeStore?.isUsLocale"
        class="klarna"
        data-key="credit-promotion-badge"
        :data-locale="capitalizeRightOfSeparator(localeStore.langCode, '-')"
        :data-purchase-amount="roundedCurrentPrice"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { useLocaleStore } from '~/store/locale';
import { useProductDataStore } from '~/store/product-data';
import { capitalizeRightOfSeparator } from '~/util/string';
import { handleOutsideClick } from '~/util/eventHandler';

const localeStore = useLocaleStore();
const productDataStore = useProductDataStore();

const props = defineProps({
  showPaymentOptions: {
    type: Boolean,
    default: false,
  },
  isHlpCarousel: {
    type: Boolean,
    default: false,
  },
  showInfoIcon: {
    type: Boolean,
    default: true,
  },
  isPdp: {
    type: Boolean,
    required: false,
    default: true,
  },
  quantity: {
    type: Number,
    required: false,
    default: 1,
  },
  sku: {
    type: String,
    required: true,
  },
  subscriptionPrice: {
    type: Number || undefined,
    required: false,
    default: undefined,
  },
});
const tooltipOpen = ref(false);
const info = ref<HTMLElement | null>(null);
const priceInfo = computed(() => (props?.sku ? productDataStore.getPriceBySku(props.sku) : undefined));
const currentPrice = computed(() => props?.subscriptionPrice || priceInfo.value?.calculated_price);
const originalPrice = computed(() => priceInfo.value?.price);

const pricesDiff = computed<boolean>(() => {
  return (
    !!originalPrice.value &&
    !!currentPrice.value &&
    originalPrice.value !== currentPrice.value &&
    currentPrice.value < originalPrice.value
  );
});
const priceDiff = computed<string | undefined>(() => {
  return pricesDiff.value && !!originalPrice.value && !!currentPrice.value
    ? (originalPrice.value - currentPrice.value).toFixed(2)
    : undefined;
});
const roundedCurrentPrice = computed<number>(() => {
  // https://developers.klarna.com/api/#metadata
  return Math.trunc(currentPrice.value * 100) * props.quantity; // remove decimals for klarna to work
});

onMounted(() => {
  document.addEventListener('click', (e: any) => {
    const isOutside = handleOutsideClick(e, info.value as HTMLElement, 'open');
    if (isOutside && tooltipOpen.value) toggleTooltip();
  });
});
onUnmounted(() => {
  document.removeEventListener('click', (e: any) => {
    const isOutside = handleOutsideClick(e, info.value as HTMLElement, 'open');
    if (isOutside && tooltipOpen.value) toggleTooltip();
  });
});

function toggleTooltip() {
  tooltipOpen.value = !tooltipOpen.value;
}
</script>

<style lang="scss">
// do NOT make this scoped, klarna tries to add the "data-v-4cb2b237" as part of the url when using the custom html el
.price-container {
  margin-bottom: 0.75rem;
  .orig-price,
  .orig-price span,
  .current-price {
    @include local-mixins.ff-text-65;
    font-size: 1rem;
    text-transform: uppercase;
    @include local-mixins.desktop {
      font-size: 1.125rem;
    }
  }
  .msrp-price {
    font-size: 0.875rem !important;
  }

  .product-title,
  .current-price {
    color: $color-neutral-cool-900;
  }

  .is-hlp-carousel-current-price {
    color: $color-neutral-white;
  }

  .orig-price,
  .orig-price span {
    color: #6a6c77;
    font-size: 1rem;
    @include local-mixins.desktop {
      font-size: 1.125rem;
    }
  }

  .current-price + .orig-price,
  .orig-price + .savings-cta {
    margin-left: 0.5rem;
  }

  .savings-cta {
    @include local-mixins.ff-text-75;
    font-size: 0.75rem;
    color: #c90c38;
  }
  .klarna {
    min-height: 23px;
    margin-top: 0.5rem;
  }
  .tooltip-container {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .tooltip-info-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    margin-left: 5px;
    svg {
      fill: #6a6c77;
    }
  }
  .tooltip-popup {
    display: none;
    flex-wrap: wrap;
    justify-content: center;
    position: absolute;
    z-index: 3;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    min-width: 250px;
    &.open {
      display: flex;
    }
    @include local-mixins.tablet_and_mobile {
      position: fixed;
      max-width: 300px;
      bottom: calc(50% - 65px);
    }
    @include local-mixins.desktop {
      top: 50%;
      bottom: unset;
      transform: translateY(-50%);
      left: calc(100% + 20px);
      // transform: translateX(-50%);
    }
  }
  .tooltip-copy {
    position: relative;
    background-color: #f5f5f5;
    border-radius: 5px;
    padding: 10px 30px 10px 10px;
    .heading {
      font-size: 12px;
      color: $color-neutral-cool-900;
    }
    p {
      font-size: 10px;
      margin-bottom: 4px;
      color: $color-neutral-cool-900;
    }
  }
  .tooltip-close {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }
  .tooltip-arrow {
    display: block;
    content: '';
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #f5f5f5;
    @include local-mixins.tablet_and_mobile {
      display: none;
    }
    @include local-mixins.desktop {
      position: absolute;
      left: -20px;
      top: calc(50% - 20px);
      border-left: none;
      border-top: 20px solid transparent; /* Adjust the size as needed */
      border-bottom: 20px solid transparent; /* Adjust the size as needed */
      border-right: 20px solid #f5f5f5; /* Adjust the color as needed */
    }
  }
}
</style>
