<template>
  <h3 class="gs-heading mgn-btm-1">{{ headingText }}</h3>
  <ScrollableContainer :id="id" :dy-rec-widget="false" :is-component-section="true">
    <GuidedSellingProductCard
      v-for="(product, i) in products"
      v-bind="product"
      :key="id + i"
      :render-mode="renderMode"
      emit-link-click="linkClick"
      @link-click="cartStore.closeCartModal()"
    />
  </ScrollableContainer>
</template>

<script setup lang="ts">
import { generateIdNoDate } from '~/util/generateId';
import { useCartStore } from '~/store/cart';

defineProps({
  headingText: {
    type: String,
    default: '',
  },
  products: {
    type: Array<object>,
    required: true,
  },
  renderMode: {
    type: String,
    default: '',
  },
});

const id = generateIdNoDate('GuidedSellingCarousel');

const cartStore = useCartStore();
</script>

<style lang="scss" scoped>
.gs-heading {
  @include local-mixins.ff-text-65;
  margin-left: 0.75rem;
  margin-bottom: 3rem;
  font-size: 1rem;
  @include local-mixins.desktop_and_tablet {
    font-size: 1.5rem;
  }
}
.scroll-container {
  gap: 1.5rem;
  @include local-mixins.desktop_and_tablet {
    gap: inherit;
  }
  .product-card-container {
    min-width: 75vw;
    @include local-mixins.desktop_and_tablet {
      min-width: 270px;
    }
  }
}

:deep(.cart-modal) {
  overflow-x: unset;
  .scroll-container {
    flex-direction: column;
    gap: 18px;
    .product-card-container-cart-modal {
      border: 1px solid $color-neutral-cool-100;
      border-radius: 4px;
      padding: 0.5rem;
      margin: 0;
      background-color: $color-neutral-white;
      .product-card {
        display: flex;
        gap: 20px;
        .product-tag {
          display: none;
        }
        .product-title {
          color: $color-neutral-cool-900;
          font-size: 1rem;
        }
        .product-details {
          display: flex;
          flex-direction: column;
          margin-top: 0;
          .price-container {
            order: 2;
            margin-bottom: 0;
          }
          .yotpo.bottomLine {
            order: 3;
          }
        }
        .image-wrapper {
          overflow: hidden;
          border-radius: 4px;
        }
        .image {
          background-color: $color-neutral-cool-50;
          transition: transform 0.2s;
          height: #{local-functions.rem-calc(80)};
          width: #{local-functions.rem-calc(80)};
        }
        &:hover {
          .image {
            transform: scale(1.1);
          }
        }
        .price-container {
          .current-price,
          .orig-price,
          .orig-price .strikethrough {
            font-size: 0.875rem;
            line-height: 1.5;
          }
          .orig-price {
            margin-left: 5px;
          }
          .savings-cta {
            display: none;
          }
        }
      }
      .add-to-cart-wrapper {
        margin-top: 0;
        .btn {
          margin-left: 100px;
          width: calc(100% - 100px);
        }
      }
    }
  }
}
</style>
