<template>
  <div v-if="localeStore?.isUsLocale" class="rewards-section mgn-btm-1-pt5">
    <div class="fire-logo-svg" />
    <div v-if="customerStore.loggedIn && state.yotpodata && !state.yotpodata.errors" class="">
      <div class="rewards-title">Hi, {{ state.yotpodata.first_name }}!</div>
      <div class="rewards-desc">
        You have
        <span class="ff-text-500"
          >{{
            state.yotpodata.points_balance > 0
              ? state.yotpodata.points_balance
              : `You\'ll earn ${Math.round(cartStore.totalPrice * 2)} points`
          }}
          {{ ` (${state.yotpodata.credit_balance_in_customer_currency}) rewards points.` }}</span
        >
        Redeem at checkout.
      </div>
    </div>
    <div v-else>
      <div class="rewards-title">Join Firestarter Rewards</div>
      <div class="rewards-desc">
        <a :href="localePath('/account?redirect=/cart')" class="sign-in ul">{{ useTranslate('LoginPage.signIn') }}</a>
        or <a :href="localePath('/create-account?redirect=/cart')" class="sign-up ul">Sign Up</a> to
        <span class="ff-text-500">{{
          itemsInCart ? `collect your ${Math.round(cartStore.totalPrice * 2)} rewards points.` : 'earn reward points.'
        }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCustomerStore } from '~/store/customer';
import { useCartStore } from '~/store/cart';
import { useLocaleStore } from '~/store/locale';

const localePath = useLocalePath();
const cartStore = useCartStore();
const customerStore = useCustomerStore();
const localeStore = useLocaleStore();
const { regionHeaderName, regionLocaleProperties } = useRegionRequestSettings();

const state = reactive({
  yotpodata: undefined as any,
});

const itemsInCart = computed<boolean>(() => !!cartStore.numItemsCart && cartStore.numItemsCart > 0);
const customer = computed(() => customerStore.customer);

watch(
  () => customer.value,
  async () => {
    if (customerStore.loggedIn && customer.value?.id) {
      await getYotpoRewardsData();
    }
  }
);

onMounted(async () => {
  if (customerStore.loggedIn && customer.value?.id) {
    await getYotpoRewardsData();
  }
});

async function getYotpoRewardsData() {
  try {
    const res = await useFetchApiLayer(`/api/yotpo-customer?customer_id=${customer.value.id}`, {
      method: 'GET',
      headers: {
        [regionHeaderName]: regionLocaleProperties.value.regionCode,
      },
    });
    // const res = await $fetch(`/api/yotpo-customer?customer_id=${customer.value.id}`);
    state.yotpodata = res;
  } catch {}
}
</script>
<style lang="scss" scoped>
.rewards-section {
  padding: 12px;
  border: 1px solid $color-neutral-cool-200;
  border-radius: 4px;
  display: grid;
  grid-template-columns: min-content;
  grid-template-areas:
    'icon title'
    'icon desc';
  .sign-in,
  .sign-up {
    color: $color-primary-600;
  }
  .rewards-title {
    @include local-mixins.ff-text-65;
    grid-area: title;
    font-size: 0.875rem;
    line-height: 20px;
  }
  .rewards-desc {
    grid-area: desc;
    font-size: 0.75rem;
    line-height: 1.5;
  }
  .fire-logo-svg {
    @include local-mixins.backgroundImage(local-functions.svg-img-source('circle-fire-logo.svg'), 40px, 38px);
    grid-area: icon;
    fill: #f36b30;
    margin-right: 0.5rem;
    @include local-mixins.desktop {
      margin-right: 1rem;
    }
  }
}
</style>
