<template>
  <div class="page-wrapper" :class="{ 'cart-page': isCartPage }">
    <a href="#main" class="skip" aria-label="Skip to main content">Skip to main content</a>
    <!-- TODO - refactor header so banner can live inside -->
    <!-- TODO - Clean up TopBanner so Swiper works as a standalone instead of just inside of DYCampaign -->
    <LazySliderTopBanner
      v-if="topBanner && !showDyCampaign"
      :top-banner="topBanner"
      :auto-play-time="topBannerAutoPlayTime"
    />
    <LazyDYCampaign
      v-if="showDyCampaign"
      :campaign_id="pencilDyCampaign.campaign_id"
      :campaign_title="pencilDyCampaign.campaign_title"
      :variations="pencilDyCampaignVariations"
    />
    <Header />
    <LazyMaintenanceBanner
      v-if="rootStore?.siteUnderMaintenance"
      :maintenance-settings="rootStore.brandSettings.site_maintenance"
    />
    <main id="main" :class="['main', { home: isHomepage }]">
      <slot />
      <ClientOnly>
        <LazyCartModal :class="showCartModal ? 'open' : ''" :lock-body-on-open="false" :modal-opened="showCartModal" />
        <LazyDealDrawer v-if="showDealDrawer" v-bind="dealDrawer" />
        <LazyLocaleModal v-if="localeModalOpen" />
      </ClientOnly>
    </main>
    <LazyFooter>
      <LazyEmailSignUp v-if="signUpFooter" v-bind="signUpFooter" />
    </LazyFooter>
  </div>
</template>

<script setup lang="ts">
import { isEmpty } from 'lodash-es';
import { useRootStore } from '~/store';
import { useCustomerStore } from '~/store/customer';
import { useNavStore } from '~/store/nav';
import { useCartStore } from '~/store/cart';
import { useLocaleStore } from '~/store/locale';
import { getFirstIndex } from '~/util/arrays';
import { extractComponent } from '~/util/contentstack/csHelpers';
import { addBodyClass } from '~/util/eventHandler';
import { useDyStore } from '~/store/dy';
import { useFeatureStore } from '~/store/feature';
import { useConnectionStore } from '~/store/connection';
import { Variation } from '~/components/DYCampaign.vue';
import { metaGen } from '~/util/metadata';
import { mapBcCartItemsToGtmEvent, GtmEventType } from '~/util/analytics';
// import { pageTypeByUrl } from '~/util/dy';
// import { DyPreOrganizedData } from '~/composables/dy-fetch';

const config = useRuntimeConfig();

await useServerInit(); // i18n called here

await useKlarna();

const rootStore = useRootStore();
const customerStore = useCustomerStore();
const navStore = useNavStore();
const cartStore = useCartStore();
const localeStore = useLocaleStore();
const { iterateBackCompatDecisions } = useDyStore();
const { setFeatureData } = useFeatureStore();
const { getConnection } = useConnectionStore();
const gtm = useGtm();

const state = reactive({
  busEmitted: false,
});

const loggedIn = computed(() => customerStore.loggedIn);
const pencilDyCampaign = computed(() => navStore.header?.pencil_banner_dy_campaign);
const showDyCampaign = computed(
  () => pencilDyCampaign?.value?.campaign_id && pencilDyCampaignVariations?.value?.length
);
const footer = computed(() => navStore.footer);
const topBannerContainer = computed(() => navStore.header?.pencil_banner_containerConnection?.edges[0]);
const topBanner = computed(() => topBannerContainer?.value?.node?.pencil_bannerConnection);
const topBannerAutoPlayTime = computed(() => topBannerContainer?.value?.node?.autoplay_time);
const newItemAdded = computed(() => cartStore.newItemAdded);
const latestAdditions = computed(() => cartStore.latestAdditions);
const showCurrentCartModal = computed(() => cartStore.showCurrentCartModal);
const localeModalOpen = computed(() => navStore.localeModalOpen);
const langCode = computed(() => localeStore.langCode);

const i18n = useLocaleHead({ addSeoAttributes: true }); // do we want computed here? This is a ref - putting here due to syntax
const route = useRoute();

const footerComputed = computed(() => getFirstIndex(footer.value?.items));
const signUpFooter = computed(() => extractComponent(footerComputed.value?.email_sign_upConnection));
const pencilDyCampaignVariations = computed(() => {
  if (!pencilDyCampaign?.value) return [];
  const arr = pencilDyCampaign?.value?.variations?.map((variation: Variation) => {
    return { ...variation, variation_type: 'content_referenceConnection' };
  });
  return arr;
});
const dealDrawer = computed(() => extractComponent(footerComputed.value?.deal_drawerConnection));
const showCartModal = computed(
  () => (newItemAdded.value && latestAdditions.value?.length) || showCurrentCartModal.value
);
// const routeParamsEmpty = computed(() => isEmpty(route.params));
const path = computed(() => route.path?.toLowerCase());
const isHomepage = computed(() => path.value === '/' + langCode.value || path.value === '/' + langCode.value + '/');
const isCartPage = computed(() => path.value === '/' + langCode.value + '/cart');
const isProductPage = computed(() => path.value?.includes('/p/'));
const showDealDrawer = computed(() => !isEmpty(dealDrawer.value) && !isCartPage?.value && !isProductPage?.value);

useHead({
  ...(metaGen(
    rootStore?.brandSettings,
    { canonical: config.public.baseUrl + route.path },
    { meta: i18n?.value?.meta as any, links: i18n?.value?.link as any }
  ) as any),
});

// await useAsyncData(async () => {
// });
// TODO - can we do this/part of this on the server?
onBeforeMount(async () => {
  // // Do not put the following within a 'Promise.all' call.
  // // This will introduce gnarly race conditions.
  await setFeatureData();
  await useCartBCValidate(); // May need to change how 'getCookie' is handled in this call
  await useIsLoggedIn();
  await useGetCart();
  await getConnection();
  // // !!!! IMPORTANT - SET GWP TEST AFTER "GETCART" to avoid dom issues on cart page
  await iterateBackCompatDecisions(); // TODO: put into process.server in setup after verifying no side-effects
});

onMounted(() => {
  if (showDealDrawer.value) {
    addBodyClass('deal-drawer-present');
  }
});

watch([route, loggedIn], ([newRoute], [oldRoute]) => {
  if (newRoute !== oldRoute) {
    state.busEmitted = false;
  }
});
watch(
  () => showCartModal.value,
  (newVal) => {
    if (newVal) {
      const modal = document.body.querySelector('.cart-modal.full-window') as HTMLElement;
      if (modal) modal.focus();
      gaViewCart();
    }
  }
);

function gaViewCart() {
  gtm?.trackEvent(mapBcCartItemsToGtmEvent(GtmEventType.viewCart, cartStore.filteredProducts));
}
</script>

<style lang="scss">
.main {
  min-height: 100vh;
  @include local-mixins.mobile {
    overflow: hidden;
  }

  &.home {
    margin-top: 0;
  }
}
.page-wrapper.cart-page {
  @include local-mixins.tablet_and_mobile {
    .dy-topbanner,
    .header-content {
      position: unset;
    }
  }
}
.skip {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  &:focus {
    position: static;
    width: auto;
  }
}
.dy-topbanner {
  position: sticky;
  top: 0;
  z-index: 3;
  @include local-mixins.tablet_and_mobile {
    position: unset;
  }
}
</style>
