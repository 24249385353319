<template>
  <div
    :class="[
      'color-swatch-wrapper flex',
      { selected: isSelected },
      { disabled: isDisabled },
      { 'flex-col has-label': newColors.includes(option?.label) && isPdp },
    ]"
  >
    <!-- TODO - if multi color in single option  - v-for="color in getColorSwatchValues(option)" -->
    <button
      :key="`color${optionInfo}`"
      class="color-swatch pointer clear-btn-style"
      :style="getBackgroundStyle(optionInfo)"
      :title="getLabel(option)"
      :aria-label="`Set Product Option ${getLabel(option)}`"
      :disabled="isDisabled"
      @click.prevent="emit('set-child', index)"
    />
    <div v-if="newColors.includes(option?.label) && isPdp" class="flex cntr new-label ff-text-500">NEW</div>
    <!-- click prevent stops product card navigation to pdp when selecting variants -->
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  option: {
    type: Object as () => any,
    required: true,
  },
  isSelected: {
    type: Boolean,
    required: true,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  index: {
    type: Number,
    required: true,
  },
  isPdp: {
    type: Boolean,
    default: false,
  },
});

const newColors = ['Bronze Metallic', 'Gunmetal Metallic', 'Cascade Metallic'];
const optionInfo = props.option?.value_data?.colors?.[0] || props.option?.value_data?.image_url;
const emit = defineEmits<{ (event: 'set-child', i: number): void }>();

const getBackgroundStyle = (color: string): string => {
  if (checkIfImage(color)) return `background-image: url(${color});   background-size: cover;`;
  return `background-color: ${hexToRGBA(color, props.isDisabled ? 0.7 : 1)}`;
};
// const getColorSwatchValues = (option: any): string => {
//   return option?.value_data?.colors;
// };
const checkIfImage = (optVal: string): boolean => {
  return optVal.includes('http');
};
const getLabel = (option: any): string | undefined => {
  return option?.label;
};
function hexToRGBA(hex: string, alpha: number): string {
  const hexValue = hex.replace('#', '');
  const red = parseInt(hexValue.substring(0, 2), 16);
  const green = parseInt(hexValue.substring(2, 4), 16);
  const blue = parseInt(hexValue.substring(4, 6), 16);

  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
}
</script>

<style lang="scss" scoped>
.color-swatch-wrapper {
  border-radius: 50%;
  border: 2px solid transparent;
  &.selected {
    border: 2px $color-primary-500 solid;
  }
  &:not(.selected):hover:not(.disabled) {
    margin: 1px; // so no jumpyness
    border: 1px $color-neutral-cool-200 solid;
  }
  &.has-label {
    border-radius: 8px;
  }
  &.disabled {
    border: 2px solid $color-neutral-black;
    position: relative;
    cursor: not-allowed;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 2px;
      background-color: $color-neutral-black;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}
.new-label {
  font-size: 11px;
  line-height: 1.5;
  margin-top: 4px;
  text-align: center;
}
.color-swatch {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  margin: 2px;
  &:focus {
    outline-width: 1px;
    outline-style: solid;
    outline-color: transparent; // -webkit-focus-ring-color;
  }
}
</style>
