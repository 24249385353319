<template>
  <div class="cart-price-section flex-col">
    <div class="prices">
      <span class="ff-text-500 margin-right">{{
        isDiscountFree(product) ? 'FREE' : localeStore.currencySymbol + formatPrice(product.price.regular * product.qty)
      }}</span>
      <span v-if="showMsrp(product)" class="strikethrough">
        {{ localeStore.currencySymbol + formatPrice(formatMsrp(product) * product.qty) }}
      </span>
    </div>
    <span v-if="product.price.regular && !isDiscountFree(product) && product.qty > 1" class="per-item">
      {{ localeStore.currencySymbol + formatPrice(+product.price.regular) }} / item
    </span>
    <span v-if="priceDiff" class="savings-cta">{{
      useTranslate('GLOBALS.youSave') + ' ' + localeStore.currencySymbol + priceDiff
    }}</span>
  </div>
</template>

<script setup lang="ts">
import { useLocaleStore } from '~/store/locale';
import { checkIsDiscountFree, findEtchingSurcharge } from '~/util/bigcommerce';

const localeStore = useLocaleStore();

const props = defineProps({
  product: {
    type: Object as () => any,
    required: true,
  },
});

const currentPrice = computed(() => props?.product.price.regular);
const originalPrice = computed(() => formatMsrp(props.product));

const pricesDiff = computed<boolean>(() => {
  return (
    !!originalPrice.value &&
    !!currentPrice.value &&
    originalPrice.value !== currentPrice.value &&
    currentPrice.value < originalPrice.value
  );
});
const priceDiff = computed<string | undefined>(() => {
  return pricesDiff.value && !!originalPrice.value && !!currentPrice.value
    ? ((originalPrice.value - currentPrice.value) * props.product.qty).toFixed(2)
    : undefined;
});

function isDiscountFree(product: any) {
  return checkIsDiscountFree(product);
}
function showMsrp(product: any) {
  return (
    product.price.msrp && product.price.regular !== product.price.msrp && product.price.regular < product.price.msrp
  );
}
function formatMsrp(product: any) {
  // Made specifically for etching
  const etchingPrice = findEtchingSurcharge(product);
  const finalPrice = product.price.msrp + etchingPrice;
  return finalPrice;
}
function formatPrice(price: number): string {
  return price?.toFixed(2);
}
</script>

<style lang="scss" scoped>
.current-price + .orig-price,
.orig-price + .savings-cta {
  margin-left: 0.5rem;
}

.savings-cta {
  @include local-mixins.ff-text-65;
  font-size: 0.75rem;
  color: #c90c38;
}

.modal-content .ff-text-500 {
  @include local-mixins.ff-text-55;
}

.per-item {
  color: $color-neutral-cool-600;
  font-size: 0.75rem;
}
.bold,
.strikethrough {
  font-size: 1rem;
}
.margin-right {
  margin-right: 4px;
}
</style>
