<template>
  <template v-if="hlpCarouselHeading && isHlpCarousel">
    <h6 class="hlp-carousel-heading">{{ hlpCarouselHeading }}</h6>
  </template>
  <div :class="['outer-wrapper', { 'product-highligh-grid': isProductHighlight }]">
    <CustomButton
      v-if="!isHlpCarousel"
      v-show="enabled"
      :icon-only="true"
      :disabled="scrollPercentage === 0"
      style-name="tertiary"
      style-color="black"
      aria-label="Previous Product Image"
      :class="['left-button', { 'btn-disabled': scrollPercentage === 0 }]"
      :style="scrollPercentage === 0 ? 'opacity: 0' : ''"
      @click="handleScroll('left')"
    >
      <ArrowLeftSLine />
    </CustomButton>
    <CustomButton
      v-if="!isHlpCarousel"
      v-show="enabled"
      :icon-only="true"
      :disabled="scrollPercentage === 100"
      style-name="tertiary"
      style-color="black"
      aria-label="Next Product Image"
      :class="['right-button', { 'btn-disabled': scrollPercentage === 100 }]"
      :style="scrollPercentage === 100 ? 'opacity: 0' : ''"
      @click="handleScroll('right')"
    >
      <ArrowRightSLine />
    </CustomButton>
    <div
      ref="scrollWrapper"
      :class="[
        'scroll-wrapper',
        {
          'few-items': fewItems,
          'is-component-section': isComponentSection,
          'cart-modal': cartModal,
          'dy-rec-widget': dyRecWidget,
          'minicart-recs': cartModal && dyRecWidget,
        },
      ]"
    >
      <div
        ref="scrollContainer"
        :class="['flex scroll-container', { 'few-items': fewItems }, { 'secondary-banner': secondaryBanner }]"
      >
        <slot />
      </div>
    </div>
    <Progressbar
      v-show="enabled && !isHlpCarousel"
      :scroll-wrapper="scrollWrapper"
      :element-size="elementSize"
      :axis="scrollAxis"
      @progressbar-update="handleProgressbarUpdate"
    />
    <template v-if="isHlpCarousel">
      <div class="hlp-carousel-progress">
        <Progressbar
          :is-hlp-carousel="isHlpCarousel"
          :scroll-wrapper="scrollWrapper"
          :element-size="elementSize"
          :axis="scrollAxis"
          @progressbar-update="handleProgressbarUpdate"
        />
        <CustomButton
          :icon-only="true"
          :disabled="scrollPercentage === 0"
          style-name="tertiary"
          style-color="black"
          aria-label="Previous Product Image"
          :class="['left-button', 'hlp-left-button', { 'btn-disabled': scrollPercentage === 0 }]"
          :style="scrollPercentage === 0 ? 'opacity: 0.5' : ''"
          @click="handleScroll('left')"
        >
          <ArrowLeftSLine />
        </CustomButton>
        <CustomButton
          :icon-only="true"
          :disabled="scrollPercentage === 100"
          style-name="tertiary"
          style-color="black"
          aria-label="Next Product Image"
          :class="['right-button', 'hlp-right-button', { 'btn-disabled': scrollPercentage === 100 }]"
          :style="scrollPercentage === 100 ? 'opacity: 0.5' : ''"
          @click="handleScroll('right')"
        >
          <ArrowRightSLine />
        </CustomButton>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
let observer: ResizeObserver | undefined;

const clickScrollPercentage = ref<number>(25);
const scrollWrapper = ref<HTMLElement | null>(null);
const scrollContainer = ref<HTMLElement | null>(null);
const scrollPercentage = ref(0);
const elementSize = ref(0);
const enabled = ref(false);

const props = defineProps({
  fewItems: {
    type: Boolean,
    default: false,
  },
  secondaryBanner: {
    type: Boolean,
    default: false,
  },
  isComponentSection: {
    type: Boolean,
    default: false,
  },
  isHlpCarousel: {
    type: Boolean,
    default: false,
  },
  hlpCarouselHeading: {
    type: String,
    default: '',
  },
  cartModal: {
    type: Boolean,
    default: false,
  },
  dyRecWidget: {
    type: Boolean,
    default: false,
  },
  showScrollBar: {
    type: Boolean,
    default: true,
  },
  scrollAxis: {
    type: String,
    default: 'X',
  },
  isProductHighlight: {
    type: Boolean,
    default: false,
  },
});

onMounted(() => {
  const element = scrollContainer.value;
  if (element) {
    observer = new ResizeObserver(resizeEvent);
    observer.observe(element);
  }
});

onUnmounted(() => {
  if (observer) {
    observer.disconnect();
    observer = undefined;
  }
});

const resizeEvent: ResizeObserverCallback = () => {
  const element = scrollContainer.value;
  const clientSize = props.scrollAxis === 'Y' ? element?.clientHeight : element?.clientWidth;
  const scrollSize = props.scrollAxis === 'Y' ? element?.scrollHeight : element?.scrollWidth;
  if (clientSize && scrollSize) {
    elementSize.value = scrollSize - clientSize;
    enabled.value = scrollSize > clientSize && props.showScrollBar;

    if (window.matchMedia('(max-width: 700px)').matches && props.isProductHighlight) {
      enabled.value = false;
    }
  }
};

const getScrollDistance = () => {
  const element = scrollContainer.value;
  let clientSize = element?.clientWidth;
  if (!clientSize) clientSize = 0;
  let scrollSize = element?.scrollWidth;
  if (!scrollSize) scrollSize = 0;

  if (scrollSize / 2.1 > clientSize) {
    clickScrollPercentage.value = props.isHlpCarousel ? clientSize * 0.75 : clientSize;
  } else {
    clickScrollPercentage.value = props.isHlpCarousel ? elementSize.value / 2.5 : elementSize.value;
  }
};

const handleScroll = (direction: 'left' | 'right') => {
  const el = scrollWrapper.value;
  if (clickScrollPercentage.value === 25) {
    getScrollDistance();
  }
  if (!el) return;
  if (direction === 'left') el.scrollLeft -= clickScrollPercentage.value;
  else el.scrollLeft += clickScrollPercentage.value;
};
const handleProgressbarUpdate = (updatedProgress: { scrollPercentage: number }) => {
  scrollPercentage.value = Math.ceil(updatedProgress.scrollPercentage);
  getScrollDistance();
};
</script>

<style scoped lang="scss">
.outer-wrapper {
  position: relative;
}
.scroll-wrapper {
  overflow-x: auto;
  scroll-behavior: smooth;
  width: 100%;
  padding-bottom: local-vars.$mob-page-margin;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  // START - ComponentSection.vue
  &.is-component-section:deep() {
    .product-card-container {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    @include local-mixins.desktop_and_tablet {
      .scroll-container {
        margin: 0 auto;
      }
      .product-card-container {
        position: relative;
        margin: 1rem local-vars.$mob-page-margin;
      }
    }
    @include local-mixins.tablet {
      .product-card-container {
        min-width: 270px;
      }
    }
    @include local-mixins.desktop {
      .product-card-container {
        min-width: 300px;
      }
      .scroll-container:has(.product-card-container:nth-last-child(n + 5)) {
        .product-card-container:last-child {
          .product-card {
            width: calc(100% + local-vars.$mob-page-margin);
            .image-wrapper {
              width: calc(100% - local-vars.$mob-page-margin);
            }
          }
        }
      }
    }
  }
  &.few-items:deep() {
    @include local-mixins.desktop {
      .product-card-container {
        max-width: 40%;
      }
    }
  }
  // END - ComponentSection.vue
  &.minicart-recs {
    .scroll-container {
      flex-direction: column;
    }
    :deep(.product-card) {
      color: $color-neutral-cool-900;
    }
    :deep(.product-title) {
      font-size: 1rem;
      color: $color-neutral-cool-900;
    }
  }
}
.scroll-container {
  gap: local-vars.$mob-page-margin;
  justify-content: space-between;
  @include local-mixins.desktop_and_tablet {
    gap: 1.38rem;
    margin: 0;
  }
}
.left-button,
.right-button {
  position: absolute;
  top: calc(50% - 12px);
  transform: translateY(-50%);
  z-index: map.get(local-vars.$zindex, 'dropdown');
  @include local-mixins.tablet_and_mobile {
    display: none;
  }
  &.btn-disabled {
    pointer-events: none;
  }
}
.left-button {
  left: -2.5rem;
}
.right-button {
  right: -2.5rem;
}
.btn:deep() {
  background-color: rgba(255, 255, 255, 0.8);
  height: #{local-functions.rem-calc(50)};
  width: #{local-functions.rem-calc(50)};
  border-radius: 50%;
  border: none;
  padding: 0;
  :deep(svg) {
    fill: $color-neutral-black;
    margin: 0;
    height: 32px;
    width: 37px;
  }
}
:deep(.progressbar--container) {
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
  @include local-mixins.tablet_and_mobile {
    margin: 1rem 1rem 0 1rem;
    width: calc(100% - 2rem);
  }
  @include local-mixins.tablet {
    margin: 1rem auto 0 auto;
  }
}

.product-highligh-grid:deep() {
  @include local-mixins.mobile {
    padding-right: local-vars.$mob-page-margin;
    & .left-button,
    & .right-button {
      display: none;
    }
    & .scroll-container {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .product-card {
        margin-bottom: 0.5rem;

        .star-container {
          width: 11px !important;
          height: 11px !important;
          svg {
            width: 11px !important;
            height: 11px !important;
          }
        }
        .yotpo-sr-bottom-line-right-panel div {
          font-size: 12px !important;
        }
      }

      .option-mobile-wrapper .opt-button {
        display: none;
      }

      .options-select-mobile {
        display: block;
        border-color: #b2b2b2;
        margin: 0;
      }

      .options-wrapper {
        margin-top: 0;
        row-gap: 0;
      }
      .product-card-buttons {
        flex-direction: column;
        > *:not(:last-child) {
          margin-bottom: 1rem;
        }
        .btn {
          width: 100%;
          margin: 0;
        }
      }
    }
  }
}
.hlp-carousel-progress {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: #{local-functions.rem-calc(15)};
  .hlp-carousel {
    @include local-mixins.desktop {
      max-width: 12.5rem;
      margin-right: 12.5rem;
    }
  }
  .progressbar--container {
    margin-top: 0;
    margin-right: auto;
    @include local-mixins.desktop {
      margin-right: #{local-functions.rem-calc(40)};
    }
  }
  .hlp-left-button,
  .hlp-right-button {
    display: block;
    position: static;
    transform: none;
  }

  .hlp-left-button {
    margin-right: #{local-functions.rem-calc(15)};
  }
}
.hlp-carousel-heading {
  @include local-mixins.ff-hlp-93;
  color: #f9f2e6;
  text-transform: uppercase;
  text-align: center;
  font-size: #{local-functions.rem-calc(45)};
  line-height: 1;
  margin: 0 0 #{local-functions.rem-calc(15)} 0;

  @include local-mixins.desktop {
    font-size: #{local-functions.rem-calc(95)};
  }
}
</style>
