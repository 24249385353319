<template>
  <div>
    <div v-if="header" v-html="header" />
    <div class="alternating-images-container desk-hor-mob-col">
      <div class="content-section flex-col">
        <div
          v-for="(item, i) in props.items"
          :key="'button item' + i"
          class="item"
          :class="{ selected: selectedIndex === i }"
        >
          <button @click="setSelected(i)">{{ item?.header }}</button>
          <div class="item-content-wrapper">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="content" v-html="items?.[selectedIndex]?.content" />
            <div v-if="selectedIndex === i" class="img-section tab-mob-only">
              <CloudinaryImage
                v-if="currentPublicId"
                :public-id="currentPublicId"
                :options="imageOptions"
                :alt="useImgAlt(getAlt(items?.[selectedIndex]?.image))"
              />
            </div>
          </div>
        </div>
      </div>
      <template v-if="deviceStore?.device?.isDesktop">
        <div class="img-section desk-only">
          <CloudinaryImage
            v-if="currentPublicId"
            :public-id="currentPublicId"
            :options="imageOptions"
            :alt="useImgAlt(getAlt(items?.[selectedIndex]?.image))"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CloudinaryAsset } from '@solo-stove/types/contentstack/stove/cloudinary';
import { getImageAlt } from '~/util/contentstack/csHelpers';
import { useDeviceStore } from '~/store/device';

const deviceStore = useDeviceStore();

const props = defineProps({
  title: {
    type: String,
    default: undefined,
  },
  items: {
    type: Array as () => any[],
    default: [] as any[],
  },
  header: {
    type: String,
    default: undefined,
  },
});

const selectedIndex = ref(0);
const currItem = ref(props.items?.[selectedIndex.value]);
const currImage = computed<CloudinaryAsset>(() => currItem.value?.image?.[0]);
const currentPublicId = computed<string | undefined>(() => currImage.value?.public_id);
const imageOptions = reactive({
  width: deviceStore.isMobile ? 368 : deviceStore.isTablet ? 1279 : 673,
  height: deviceStore.isMobile ? 255 : deviceStore.isTablet ? 870 : 468,
  quality: 'auto',
});
// watch(
//   () => embedShow.value,
//   (updated) => {
//     if (updated === true) setup();
//   }
// );

async function setSelected(index: number) {
  selectedIndex.value = index;
  currItem.value = props.items?.[index];
  await nextTick();
}

function getAlt(item: any): string | undefined {
  return getImageAlt(item);
}
</script>

<style lang="scss">
.alternating-images-container {
  .content {
    h3 {
      display: none;
    }
    p {
      margin-bottom: 0;
      font-size: 0.875rem;
    }
  }
}
</style>

<style lang="scss" scoped>
$animation: all 0.5s ease;
.alternating-images-container {
  @include local-mixins.xxl {
    padding: 0 2rem;
  }
  @include local-mixins.tablet_and_mobile {
    padding: 0 1rem;
  }
}
button {
  @include local-mixins.ff-text-65;
  color: $color-neutral-cool-400;
  background: none;
  border: none;
  font-size: 1.5rem;
  padding: 0.5rem 0;
  cursor: pointer;
  &:hover,
  &:focus {
    outline: none;
    color: $color-neutral-cool-900;
  }
  @include local-mixins.tablet_and_mobile {
    display: flex;
    width: 100%;
    line-height: 2;
    font-size: 1.125rem;
    &::after {
      content: '';
      transform: rotate(0deg);
      transition: $animation;
      display: block;
      margin-left: auto;
      min-width: 35px;
      @include local-mixins.backgroundMaskImage(
        local-functions.svg-img-source('arrow-down-s-line.svg'),
        $color-neutral-black,
        35px,
        35px
      );
    }
  }
}
.img-section {
  .image {
    border-radius: 0.5rem;
    height: auto;
    width: 100%;
  }
  @include local-mixins.desktop {
    width: 50%;
  }
  @include local-mixins.tablet_and_mobile {
    margin-top: 1.5rem;
  }
}
.content-section {
  .item {
    position: relative;
    .item-content-wrapper {
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      transition: $animation;
    }
    &.selected {
      .item-content-wrapper {
        transition: $animation;
        overflow: visible;
        opacity: 1;
        max-height: 800px;
      }
      button {
        color: $color-neutral-cool-900;
      }
    }
    @include local-mixins.desktop {
      padding-left: 1rem;
      max-width: 85%;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 2px;
        height: 0;
        background-color: $color-primary-500;
        transition: $animation;
      }
      &.selected {
        &::before {
          height: 100%;
        }
      }
    }
    @include local-mixins.tablet_and_mobile {
      padding: 0.5rem 0;
      border-top: 1px solid $color-neutral-cool-200;
      &:last-child {
        border-bottom: 1px solid $color-neutral-cool-200;
      }
      &.selected {
        button {
          &::after {
            transform: rotate(-180deg);
            transition: $animation;
          }
        }
      }
    }
  }
  + .content-section {
    margin-top: 6rem;
    @include local-mixins.tablet_and_mobile {
      margin-top: 3rem;
    }
  }

  @include local-mixins.desktop {
    width: 50%;
    padding-right: 3rem;
    row-gap: 1rem;
  }
  @include local-mixins.tablet_and_mobile {
    flex-direction: column;
    picture {
      width: 100%;
    }
    .item.selected {
      padding-bottom: 0.5rem;
    }
  }
}
</style>
