<template>
  <div class="pricing">
    <ul>
      <li>
        <div class="row-label">
          <div>{{ useTranslate('CartSlideout.subtotal') }}:</div>
        </div>
        <div>
          {{ localeStore.currencySymbol + formatPrice(cartStore.totalPrice) }}
        </div>
      </li>
      <li>
        <div class="row-label">
          <div>{{ useTranslate('CartSlideout.shipping') }}:</div>
        </div>
        <div>
          {{ cartStore.freeShippingProductInCart ? 'FREE' : cartStore.freeShippingMessaging }}
        </div>
      </li>
      <li v-if="localeStore?.isUsLocale">
        <div class="row-label">
          <div>Estimated Tax:</div>
        </div>
        <div>Calculated at checkout</div>
      </li>
      <li v-if="cartStore.couponActive">
        <div class="row-label">
          <div>Coupon:</div>
        </div>
        <div
          v-if="typeof cartStore.coupon.discounted_amount === 'number' && cartStore.coupon.discounted_amount > 0"
          class="highlight"
        >
          -{{ localeStore.currencySymbol + formatPrice(cartStore.coupon.discounted_amount) }}
        </div>
      </li>
      <li class="total-row">
        <div class="flex between">
          <div class="row-label">
            <strong>{{ useTranslate('CartSlideout.total') }}:</strong>
          </div>
          <div class="grand-tot ff-text-500">
            {{ localeStore.currencySymbol + formatPrice(cartStore.totalPlusTax) }}
          </div>
        </div>
        <template v-if="roundedTotalPrice">
          <PayPalPayLater :value="cartStore.totalPlusTax" placement="cart" />
          <klarna-placement
            v-if="!localeStore?.isUsLocale"
            class="klarna"
            data-key="credit-promotion-badge"
            :data-locale="capitalizeRightOfSeparator(localeStore.langCode, '-')"
            :data-purchase-amount="roundedTotalPrice"
          />
        </template>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { refreshKlarna } from '~/util/klarna';
import { useLocaleStore } from '~/store/locale';
import { useCartStore } from '~/store/cart';
import { capitalizeRightOfSeparator } from '~/util/string';

const cartStore = useCartStore();
const localeStore = useLocaleStore();

onMounted(async () => {
  await nextTickRefreshKlarna();
});
onUpdated(async () => {
  await nextTickRefreshKlarna();
});

const roundedTotalPrice = computed<number>(() => {
  return Math.trunc(cartStore.totalPlusTax * 100);
});

async function nextTickRefreshKlarna() {
  await nextTick(); // makes sure 'data-purchase-amount' on klarna html has updated in DOM
  refreshKlarna();
}

function formatPrice(price: number | string): string {
  const priceToFormat = typeof price === 'string' ? parseFloat(price) : price;
  return priceToFormat?.toFixed(2);
}
</script>

<style lang="scss" scoped>
.klarna {
  margin-top: 0.5rem;
}
.pricing {
  width: 100%;
  text-align: right;
  line-height: 150%;
  .row-label {
    margin-right: 0.5rem;
  }
  li {
    font-size: 0.875rem;
    color: $color-neutral-cool-900;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 0;
    &.total-row {
      flex-direction: column;
      align-items: flex-start;
      font-size: 1rem;
      padding: 0.75rem 0;
      border-top: 1px solid $color-neutral-cool-200;
      border-bottom: 1px solid $color-neutral-cool-200;
      .flex.between {
        width: 100%;
      }
    }
  }
}
</style>
