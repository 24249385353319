<template>
  <div
    v-if="!cartStore.hasSubscriptions"
    :class="['coupon-section input-open mgn-top-1-pt5 mgn-btm-1-pt5', { 'code-applied': cartStore.couponActive }]"
  >
    <div :class="['coupon-header']" @click="toggleCartDisplay()">
      {{ useTranslate('CartSlideout.couponCode') }}
      <div :class="['plus-minus', { active: isCouponOpen }]" />
    </div>

    <template v-if="cartStore.couponActive">
      <div class="coupon-active-section">
        <div
          v-if="typeof cartStore.coupon.discounted_amount === 'number' && cartStore.coupon.discounted_amount > 0"
          class="coupon-savings highlight"
        >
          -{{ localeStore.currencySymbol + formatPrice(cartStore.coupon.discounted_amount) }}
        </div>
        <div class="coupon-code ff-text-500">
          {{ cartStore.coupon.code }}
        </div>
        <div
          class="remove-coupon pointer ul"
          role="button"
          :aria-label="`Remove ${useTranslate('CartSlideout.couponCode')}`"
          @click="removeCompCoupon(cartStore.coupon.code)"
        >
          Remove
        </div>
      </div>
    </template>

    <div v-if="!cartStore.couponActive" class="block-container">
      <div class="section-block-container">
        <Input
          :not-valid-override="!!cartStore.couponError"
          placeholder="Enter code"
          name="coupon code"
          emit-input="text-input"
          @text-input="validateCoupon"
        >
          <template v-if="cartStore.couponError" #errorMessage>
            {{ cartStore.couponError }}
          </template>
        </Input>
        <CustomButton
          :is-small="true"
          :disabled="cartStore.cartActionInProgress"
          :aria-attrs="{
            'aria-label': 'Apply Coupon',
            'aria-disabled': cartStore.cartActionInProgress,
          }"
          style-name="secondary"
          style-color="black"
          @click-button="applyCoupon()"
        >
          Apply
        </CustomButton>
      </div>
    </div>

    <MentionMe class="coupon-mention-me" render-type="cart" />
  </div>
</template>

<script setup lang="ts">
import { useLocaleStore } from '~/store/locale';
import { useCartStore } from '~/store/cart';
import { refreshKlarna } from '~/util/klarna';

const cartStore = useCartStore();
const localeStore = useLocaleStore();

const state = reactive({
  couponCode: '',
});

async function applyCoupon(): Promise<void> {
  if (state.couponCode) {
    await useAddCoupons(state.couponCode);
    if (!cartStore.couponError) {
      await nextTickRefreshKlarna();
    }
  }
}
async function removeCompCoupon(code: string): Promise<void> {
  await useRemoveCoupon(code || state.couponCode);
  if (!cartStore.couponError) {
    await nextTickRefreshKlarna();
  }
}
async function nextTickRefreshKlarna() {
  await nextTick(); // makes sure 'data-purchase-amount' on klarna html has updated in DOM
  refreshKlarna();
}

function formatPrice(price: number | string): string {
  const priceToFormat = typeof price === 'string' ? parseFloat(price) : price;
  return priceToFormat?.toFixed(2);
}

function validateCoupon(e: { name: string; value: string }) {
  if (e && e.value) state.couponCode = e.value;
}

const isCouponOpen = ref(false);
function toggleCartDisplay() {
  const couponCodeHeader = document.getElementsByClassName('coupon-header');
  let i;
  for (i = 0; i < couponCodeHeader.length; i++) {
    isCouponOpen.value = couponCodeHeader[i].classList.toggle('open');
  }
}
</script>

<style lang="scss" scoped>
$content-transition: all 0.4s ease-out;
.coupon-section {
  display: grid;
  gap: 6px;
  grid-template-areas:
    'button button'
    'content content'
    'referral referral';
  &.input-open {
    grid-template-areas:
      'button button'
      'content content'
      'referral referral';
  }
  &.code-applied {
    grid-template-areas:
      'button button'
      'code savings'
      'remove remove'
      'referral referral';
    @include local-mixins.mobile {
      grid-template-areas:
        'button button'
        'code savings'
        'remove remove'
        'referral referral';
    }
  }
  .coupon-mention-me {
    grid-area: referral;
    margin-top: 10px;
    font-size: 0.75rem;
  }
  .coupon-savings {
    grid-area: savings;
    text-align: right;
  }
  .coupon-code {
    grid-area: code;
  }
  .remove-coupon {
    grid-area: remove;
    @include local-mixins.desktop {
      text-align: right;
    }
  }

  .coupon-header {
    @include local-mixins.ff-text-65;
    cursor: pointer;
    grid-area: button;
    background: none;
    padding: 0;
    border: none;
    color: $color-neutral-cool-900;
    font-size: 0.75rem;
    line-height: 150%;
    display: flex;
    &.open {
      ~ .block-container .section-block-container {
        overflow: visible;
        max-height: 500px;
      }
      ~ .coupon-active-section {
        overflow: visible;
        max-height: 500px;
        display: contents;
      }
    }
  }

  .section-block-container {
    display: flex;
    overflow: hidden;
    max-height: 0;
  }
  .coupon-active-section {
    overflow: hidden;
    max-height: 0;
  }

  .block-container {
    grid-area: content;
    margin-top: 0.25rem;
    display: flex;
    flex-direction: column;
  }
  .input-component-container {
    width: 73%;
    :deep() .solo-input-parent {
      border: 1px solid $color-neutral-cool-200;
    }
  }
  .btn {
    width: 25%;
    margin-left: auto;
    text-transform: capitalize;
  }
  .highlight {
    color: $color-primary-600;
  }
}

.plus-minus {
  grid-area: test;
  margin-left: auto;
  position: relative;
  width: 16px;
  height: 16px;
  cursor: pointer;
  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 100%;
    top: 0;
    left: 50%;
    background-color: $color-neutral-black;
    transition: transform 0.5s ease;
  }
  &::before {
    transform: translateX(-50%) rotate(0);
  }
  &::after {
    transform: translateX(-50%) rotate(90deg);
  }
  &.active::before {
    transform: translateX(-50%) rotate(90deg);
  }
  &.active::after {
    transform: translateX(-50%) rotate(-90deg);
  }
}
</style>
