<template>
  <div
    v-if="product"
    :class="[
      {
        'product-card-container': !renderInCartModal,
        'product-card-container-cart-modal': renderInCartModal,
        'search-page': renderSearch,
        'plp-page': renderPlp,
        'search-dropdown-height': dropdownSearchHeight,
      },
    ]"
  >
    <NuxtLink
      class="product-card"
      :to="localePath(productUrl)"
      :custom="!pdpLinkEnabled"
      @click="linkClickEmitter($event)"
    >
      <CloudinaryImage
        :public-id="imagePublicId"
        :options="{ width: state.imgWidth, height: state.imgHeight, quality: state.quality }"
        :alt="title"
        :image-classes-override="['image']"
        theme="dark"
      />
      <div class="product-details">
        <h3 class="product-title h4 ff-text-500" v-text="title" />
        <!-- Prevent card nav clicking -->
        <PriceSection
          :is-hlp-carousel="true"
          :sku="selectedVariant?.bc_variant?.sku || ''"
          :is-pdp="false"
          @click.prevent
        />
      </div>
    </NuxtLink>
    <ProductOptions
      class="hlp-options-container"
      :sku="baseProduct?.sku"
      :is-hlp-carousel="true"
      :option-default-override-sku="defaultVariantSku"
      :product-variations="productVariations"
      @product-options:update="updateOption"
      @modifiers-changed="setProductModifiers"
    />

    <AddToCartButton
      v-if="show_add_to_cart"
      class="hlp-cta-button"
      style="margin-top: auto"
      :product="cartProduct"
      :variant="state.bcVariant"
      :disable-btn="maxInCart || bcVariantDisabled"
      :is-variant="hasOptions"
      :quantity="quantity"
      :images="imagesSwap"
      :is-small="true"
      :add-to-card-cta-override="useTranslate('GLOBALS.buyNow') || 'Buy Now'"
      @added-to-cart="addedEvent"
    >
      <template #icon>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="26"
          viewBox="0 0 18 26"
          fill="none"
          class="cta-button-icon"
        >
          <path
            d="M16.8755 23.6995H15.3495C16.5145 22.4634 17.5517 20.6396 17.5497 18.0725C17.5457 13.3342 14.8389 13.1363 15.6946 9.73299C15.7106 9.6724 15.6607 9.58959 15.6009 9.60979C13.65 10.2319 12.9279 12.6758 12.7903 12.6758C13.0257 8.96953 12.4891 4.34432 8.04682 0.026105C7.97899 -0.0385268 7.86928 0.026105 7.89123 0.119013C9.17783 5.5804 7.24892 9.15534 5.96631 9.80772C5.27214 7.21639 3.3572 6.5115 2.79269 6.54382C2.73684 6.54786 2.70094 6.60441 2.71689 6.65894C3.9277 10.9267 0.472821 12.7889 0.47681 17.6241C0.478805 20.4659 1.52604 22.4109 2.69695 23.6975H1.15103C1.04331 23.6975 0.95754 23.7843 0.95754 23.8934V25.8021C0.95754 25.9111 1.04531 25.998 1.15103 25.998H6.35329L6.22563 25.8849C3.5866 23.2491 3.21757 17.7918 7.71769 14.338C7.7895 14.2835 7.88923 14.3441 7.87327 14.4329C7.30079 17.4827 7.80745 19.4459 9.2656 21.1102C10.4265 19.9872 11.392 17.82 11.6234 17.2747C11.6493 17.2121 11.7291 17.1959 11.779 17.2424C14.9227 20.2074 13.2431 24.5478 12.16 25.8869L12.0403 26H16.8735C16.9812 26 17.067 25.9132 17.067 25.8041V23.8954C17.067 23.7864 16.9792 23.6995 16.8735 23.6995"
            fill="#FF6A39"
          />
        </svg>
      </template>
    </AddToCartButton>
  </div>
</template>

<script setup lang="ts">
import { ProductV2 } from '@solo-stove/types/contentstack/stove/product';
import { CloudinaryAsset } from '@solo-stove/types/contentstack/stove/cloudinary';
import { maxCartProducts } from '~/util/bigcommerce';
import { GtmEventType, mapProductCardsItemsToGtmEvent } from '~/util/analytics';
import { hasAncestorWithClass } from '~/util/eventHandler';
import { useDeviceStore } from '~/store/device';
import { useCartStore } from '~/store/cart';

const localePath = useLocalePath();
const deviceStore = useDeviceStore();
const cartStore = useCartStore();

const emit = defineEmits(['added', 'linkClick', 'closer']);
type PCLinkClickEmit = 'linkClick' | 'closer';
type PCAddedEmit = 'added';

const props = defineProps({
  emitLinkClick: {
    type: String as () => PCLinkClickEmit,
    default: undefined,
  },
  emitAdded: {
    type: String as () => PCAddedEmit,
    required: false,
    default: undefined,
  },
  product: {
    type: Object as () => ProductV2,
    required: true,
  },
  isDynamicSection: {
    type: Boolean,
    default: false,
    required: false,
  },
  queryID: {
    type: String,
    default: undefined,
  },
  renderMode: {
    type: String,
    default: '',
  },
  index: {
    type: Number,
    default: undefined,
  },
  /* eslint-disable vue/prop-name-casing */
  show_add_to_cart: {
    type: Boolean,
    default: false,
  },
  show_short_desc: {
    type: Boolean,
    default: false,
  },
  show_whats_included: {
    type: Boolean,
    default: false,
  },
  link_to_pdp: {
    type: String as () => 'enabled' | 'disabled',
    default: 'enabled',
  },
  defaultVariantSku: {
    type: String,
    default: undefined,
  },
  secondary_button_text: {
    type: String,
    default: 'Learn More',
  },
  rowSize: {
    type: Number,
    default: 4,
  },

  /* eslint-enable vue/prop-name-casing */
});

const state = reactive({
  imgWidth: deviceStore.isMobile ? 480 : 504,
  imgHeight: deviceStore.isMobile ? 480 : 504,
  quality: 'auto',
  bcVariant: props.product?.product_offerings?.product_variations?.[0]?.bc_variant,
  productModifiers: [] as any[],
});

const imagePublicId = computed(() => imagesSwap.value?.[0]?.public_id);

const dropdownSearchHeight = computed<boolean>(() => {
  return props.renderMode === 'dropdown-search' && props.show_add_to_cart;
});
const renderSearch = computed<boolean>(() => {
  return props.renderMode === 'search';
});
const renderPlp = computed<boolean>(() => {
  return props.renderMode === 'plp';
});
const renderInCartModal = computed<boolean>(() => {
  return props.renderMode === 'cartModal';
});

const pdpLinkEnabled = computed<boolean>(() => {
  return props.link_to_pdp === 'enabled';
});
const productDetails = computed(() => {
  return props.product?.product_details;
});

const productVariations = computed(() => {
  return props.product?.product_offerings?.product_variations;
});

const options = computed(() => {
  const variations = productVariations?.value;
  return variations?.length ? variations?.map((option: any) => option?.bc_variant) : [];
});

const hasOptions = computed<boolean>(() => {
  return !!options?.value?.length;
});
const optionIndex = computed<number>(() => {
  let locatedIndex = 0;
  options.value.find((option: any, i: number) => {
    if (option?.id === state.bcVariant?.id) {
      locatedIndex = i;
      return true;
    }
    return false;
  });
  return locatedIndex;
});
const selectedVariant = computed(() => {
  const productVariations = props?.product?.product_offerings?.product_variations;
  return productVariations && productVariations[optionIndex.value] ? productVariations[optionIndex.value] : null;
});
const bcVariantDisabled = computed<boolean>(() => {
  return selectedVariant.value?.bc_variant?.purchasing_disabled || false;
});
const imagesSwap = computed<CloudinaryAsset[]>(() => {
  const newImages: Array<any> = selectedVariant.value
    ? (selectedVariant.value?.thumb?.length && selectedVariant.value?.thumb) || []
    : [];
  return newImages.concat(props.product?.product_offerings?.base_image_set);
});
const title = computed(() => {
  return selectedVariant?.value?.display_title || baseProduct?.value?.name;
  // return baseProduct?.value?.name;
});
const baseProduct = computed(() => {
  return props.product?.product_offerings?.bc_primary_product?.product;
});
const cartProduct = computed<any>(() => {
  return {
    ...baseProduct?.value,
    ...(state.productModifiers?.length > 0 && { variantId: selectedVariant?.value?.bc_variant?.id }),
  };
});
const productUrl = computed<any>(() => {
  return {
    path: props.product?.url,
    query: {
      ...(state.bcVariant?.sku && { sku: state.bcVariant.sku }),
      ...(props.queryID && { queryID: props.queryID }),
    },
  };
});
const quantity = computed<number>(() => {
  return productDetails?.value?.min_quantity || 1;
});
const maxInCart = computed<boolean>(() => {
  return maxCartProducts(cartStore.products, baseProduct?.value, quantity.value, productDetails?.value?.max_quantity);
});
function addedEvent() {
  if (props.emitAdded) {
    emit(props.emitAdded);
  }
}

function linkClickEmitter(event: MouseEvent) {
  const target = event.target as HTMLElement;
  const hasAncestor = hasAncestorWithClass(target.parentElement, 'options-container');
  // only track if not selecting variants/a2c
  if (!hasAncestor || target.classList.contains('product-card')) {
    const product = {
      product: props.product?.product_offerings?.bc_primary_product?.product,
      variant: selectedVariant.value?.bc_variant,
      hasVariants: hasOptions.value,
      index: props.index,
      quantity: quantity.value,
    };
    const gtm = useGtm();
    gtm?.trackEvent(mapProductCardsItemsToGtmEvent(GtmEventType.selectItem, [product as any]));
  }

  if (props.emitLinkClick) emit(props.emitLinkClick);
}

function updateOption(updatedVariant: any) {
  state.bcVariant = updatedVariant;
}
function setProductModifiers(modData: any) {
  state.productModifiers = modData as any;
}
</script>

<style lang="scss">
:deep('.current-price') {
  color: $color-neutral-white !important;
}
.product-card {
  .additional-info,
  .additional-info p,
  .additional-info a {
    font-size: 0.75rem;
    line-height: 150%;
    max-width: fit-content;
  }
  .gs-short-desc {
    p {
      color: $color-neutral-cool-500;
      text-overflow: ellipsis;
      text-wrap: nowrap;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
</style>

<style lang="scss" scoped>
.hlp-cta-button {
  :deep(.btn) {
    @include local-mixins.ff-hlp-93;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f9f2e6;
    color: #222222;
    padding: 1.1rem 1.5rem;
    gap: 0.5rem;
    border-radius: 8px;
    font-size: 1.438rem;
    line-height: 1;
    height: 3.75rem;
    text-transform: uppercase;
    cursor: pointer;
    border: none;
    &[disabled] {
      opacity: 0.5;
    }
  }
  :deep(.cta-button-text) {
    padding: 0.6rem 0 0;
  }
}

.section-wrapper--hlp-product-carousel {
  .product-card-container {
    min-width: 70vw;
    width: 70vw;
    @include local-mixins.desktop_and_tablet {
      min-width: calc(33% + 3.125rem);
      width: calc(33% + 3.125rem);
    }
    .hlp-options-container,
    .product-details,
    .hlp-cta-button {
      padding-left: #{local-functions.rem-calc(30)};
      @include local-mixins.desktop_and_tablet {
        padding-left: #{local-functions.rem-calc(60)};
      }
    }
  }
}

.product-card-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  @include local-mixins.desktop {
    padding-right: 0;
  }
  .product-card {
    display: block;
    color: $color-neutral-white;
  }
  .product-title {
    @include local-mixins.ff-hlp-93;
    text-transform: uppercase;
    color: $color-neutral-white;
    font-size: #{local-functions.rem-calc(30)};
    line-height: 1;
    @include local-mixins.desktop {
      font-size: #{local-functions.rem-calc(40)};
    }
  }
  .product-details {
    margin-top: 8px;
    @include local-mixins.desktop {
      margin-top: 12px;
    }
  }
  .gs-product-tag {
    width: fit-content;
    font-weight: 600;
    font-size: 0.7rem;
    line-height: 150%;
    border-radius: 2px;
    color: $color-primary-300;
  }
  .gs-product-caption {
    color: #ababab;
    font-size: 0.75rem;
    line-height: 1;
    font-weight: 600;
    opacity: 1;
    transition: opacity ease-in 0.2s;
    &-height {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%) rotate(-90deg);
      writing-mode: sideways-lr;
      z-index: map.get(local-vars.$zindex, 'page');
    }
    &-diameter {
      position: absolute;
      left: 50%;
      bottom: calc(0% + 0.5rem);
      transform: translateX(-50%);
      z-index: map.get(local-vars.$zindex, 'page');
    }
  }

  :deep(.price-container) {
    .current-price,
    .orig-price,
    .orig-price span {
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 25.2px;
      letter-spacing: -0.02em;
    }
  }
  :deep(.options-container) {
    .options-wrapper {
      margin-bottom: #{local-functions.rem-calc(30)};
      @include local-mixins.desktop_and_tablet {
        margin-bottom: #{local-functions.rem-calc(15)};
      }
    }
    .selected-title {
      display: none;
    }
    .color-swatch {
      height: 20px;
      width: 20px;
    }
    .variant-wrapper {
      width: 100%;

      .option {
        padding: 8px;
        font-size: 0.75rem;
      }
      &.opt-button:not(.selected) {
        border: $color-neutral-cool-200 1px solid;
        border-radius: 3px;
        &:hover,
        &:focus {
          border: $color-neutral-black 1px solid;
        }
      }
      &.selected {
        border: $color-primary-500 2px solid;
        border-radius: 3px;
      }
      .select-expanded {
        @include local-mixins.tablet_and_mobile {
          min-width: revert;
          width: 100%;
        }
      }
    }
  }
}
</style>
