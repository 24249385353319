<template>
  <ScrollableContainer
    v-if="localeStore?.isUsLocale && !cartModal"
    :id="state.id"
    :cart-modal="cartModal"
    :dy-rec-widget="true"
  >
    <div
      v-for="(product, i) in products"
      :key="product.bcProductId + '-rec-widget-' + i"
      class="dy-card-container"
      @click="() => engagementEvent(product.slotId)"
    >
      <NuxtLink :to="product.url" class="dy-product-card" @click="cartStore.closeCartModal()">
        <div class="image-wrapper">
          <CloudinaryImage
            :public-id="getPublicId(product.imageUrl)"
            :hover-public-id="product.image_hover_url && getPublicId(product.image_hover_url)"
            :options="{ width: state.imgSize, height: state.imgSize }"
            :alt="product.name"
            :image-classes-override="['image']"
          />
        </div>

        <div class="product-details">
          <h3 class="product-title h4 ff-text-500" v-text="product.name" />
          <YotpoStarRating :product-id="product.bcProductId" :yotpo-key="yotpoKey" />
          <PriceSection :sku="product.sku" :is-pdp="false" :show-info-icon="false" />
        </div>
      </NuxtLink>
      <!-- TODO - click tracking with location ???? -->
      <AddToCartButton
        :product="cartProducts[i]"
        :is-small="true"
        :secondary-button="isCartPage || cartModal"
        :is-cart-page="isCartPage"
        :is-variant="product.hasVariants"
        :variant="cartVariant(product)"
      />
    </div>
  </ScrollableContainer>
  <div
    v-for="(product, i) in products"
    v-else-if="localeStore?.isUsLocale"
    :key="product.bcProductId + '-rec-widget-' + i"
    class="dy-card-container dy-card-container--modal"
    @click="() => engagementEvent(product.slotId)"
  >
    <NuxtLink :to="product.url" class="dy-product-card" @click="cartStore.closeCartModal()">
      <div class="image-wrapper">
        <CloudinaryImage
          :public-id="getPublicId(product.imageUrl)"
          :hover-public-id="product.image_hover_url && getPublicId(product.image_hover_url)"
          :options="{ width: state.imgSize, height: state.imgSize }"
          :alt="product.name"
          :image-classes-override="['image']"
        />
      </div>

      <div class="product-details">
        <h3 class="product-title h4 ff-text-500" v-text="product.name" />
        <YotpoStarRating :product-id="product.bcProductId" :yotpo-key="yotpoKey" />
        <PriceSection :sku="product.sku" :is-pdp="false" :show-info-icon="false" />
      </div>
    </NuxtLink>
    <!-- TODO - click tracking with location ???? -->
    <AddToCartButton
      :product="cartProducts[i]"
      :is-small="true"
      :secondary-button="isCartPage || cartModal"
      :is-variant="product.hasVariants"
      :variant="cartVariant(product)"
    />
  </div>
</template>

<script setup lang="ts">
/* eslint-disable vue/prop-name-casing */
import { isNaN } from 'lodash-es';
import { useLocaleStore } from '~/store/locale';
import { useDyStore } from '~/store/dy';
import { useCartStore } from '~/store/cart';
import { useProductDataStore } from '~/store/product-data';
import { engagementEvent as engagementEventDy } from '~/util/dy';
import { generateIdNoDate } from '~/util/generateId';
import { triggerEventWhenSectionVisible } from '~/util/eventHandler';
import { GtmEventType, mapDyItemsToGtmEvent } from '~/util/analytics';
import { Product, RawProduct, Item, Campaign } from '~/types/dy-types';
import { useRootStore } from '~/store';
import { useDeviceStore } from '~/store/device';

const rootStore = useRootStore();
const yotpoKey = rootStore.yotpoAppKey;
const dyStore = useDyStore();
const cartStore = useCartStore();
const localeStore = useLocaleStore();
const productDataStore = useProductDataStore();
const route = useRoute();
const gtm = useGtm();
const evName = 'sectionViewedDYRecWidget';
const deviceStore = useDeviceStore();

const props = defineProps({
  campaign_id: {
    type: Number,
    required: true,
  },
  cartModal: {
    type: Boolean,
    default: false,
  },
});

const state = reactive({
  campaignId: props.campaign_id,
  imgSize: deviceStore.isMobile ? 200 : 220,
  quality: deviceStore.isMobile ? 'auto:eco' : 'auto',
  showHoverImage: [] as any,
  id: generateIdNoDate('DyRecWidget'),
});

const isCartPage = computed<boolean>(() => {
  const path = route.path?.toLowerCase();
  return path === '/' + localeStore.langCode + '/cart';
});
const currentCampaign = computed<Campaign | null>(() => {
  if (!state.campaignId) return null;
  // TODO: Remove awful magic hack
  const campaignId = state.campaignId === 189767 ? 745646 : state.campaignId;
  for (let i = 0; i < dyStore.recChoices.length; i++) {
    const dyChoice = dyStore.recChoices[i];
    if (dyChoice.id === campaignId) {
      return {
        ...dyStore.recChoices[i],
      };
    }
  }
  return null;
});
const rawProducts = computed<Item[]>(() => currentCampaign.value?.variations?.[0]?.payload?.data?.slots || []);
const products = computed<Product[]>(() => {
  const mapped = (rawProducts.value as Item[])
    .map((product) => {
      const productData: typeof product.productData = product?.productData || {};
      const modified: any = {};
      modified.sku = product?.sku;
      modified.slotId = product?.slotId;
      // DY sends everything as strings, so we need to convert them to the correct type
      (Object.keys(productData) as Array<keyof typeof productData>).forEach((key) => {
        const productDataValue = productData[key as keyof RawProduct];
        const valueType = typeof productDataValue;
        const isFloat = valueType === 'string' && !isNaN(parseFloat(productDataValue as string));
        const isTrue = valueType === 'string' && productDataValue.toString().toLowerCase() === 'true';
        const isFalse = valueType === 'string' && productDataValue.toString().toLowerCase() === 'false';

        if (isFloat) modified[key] = parseFloat(productDataValue as string) as never;
        else if (valueType === 'number') modified[key] = Number(productDataValue) as never;
        else if (isTrue) modified[key] = true as never;
        else if (isFalse) modified[key] = false as never;
        else modified[key] = productDataValue;
      });

      return modified;
    })
    .filter((product) => {
      if (product.hasVariants) {
        const options = productDataStore.getOptionsBySku(product.parentSku);
        // confirm that product with variant is in product data store and matches
        const values = options?.option_values;
        return !!values?.find(
          (opt: { id: number; sku: string; sku_id: number; option_values: any[] }) => opt.id === product.bcVariantId
        );
      } else {
        return true;
      }
    });
  return mapped;
});

const cartProducts = computed(() => {
  return products.value.map((product) => cartProduct(product));
});

const sectionViewed = () => {
  gtm?.trackEvent(mapDyItemsToGtmEvent(GtmEventType.viewItemList, products?.value));
};

onMounted(() => {
  triggerEventWhenSectionVisible(state.id, evName);
  document.getElementById(state.id)?.addEventListener(evName, sectionViewed);
});
onUnmounted(() => {
  document.getElementById(state.id)?.removeEventListener(evName, sectionViewed);
});

async function engagementEvent(slotId: string): Promise<void> {
  await engagementEventDy('SLOT_CLICK', slotId);
}
function cartProduct(product: Product) {
  const cartProduct = {
    thumb: [
      {
        name: product.name,
        file: {
          url: product.imageUrl,
        },
      },
    ],
    description: product.description,
    url: product?.url,
    id: product?.bcProductId,
    variantId: product?.bcVariantId,
    product_id: product?.bcProductId,
    name: product?.name,
    sku: product.hasVariants === true ? product?.parentSku : product?.sku,
    inventory_level: product?.inventoryLevel,
    inventory_tracking: product?.inventoryTracking,
    availability: product?.availability,
  };
  return cartProduct;
}
function cartVariant(product: Product) {
  return {
    variantId: product?.bcVariantId,
    sku: product?.sku,
  };
}
function getPublicId(url = '') {
  return url.split('/v1/')?.[1]?.split('?')?.[0];
}
</script>
<style lang="scss">
.cart-modal-content .cart-modal {
  .yotpo.bottomLine {
    .rating-star {
      font-size: 1rem;
    }
    .text-m {
      font-size: 0.6875rem;
    }
  }
}
</style>

<style lang="scss" scoped>
.dy-recs {
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.dy-card-container {
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-width: 270px;
  width: -webkit-fill-available;

  .dy-product-card {
    color: $color-neutral-cool-900;
    .product-title {
      color: $color-neutral-cool-900;
      @include local-mixins.tablet_and_mobile {
        font-size: 1rem;
      }
    }
    .product-details {
      margin-top: 12px;
    }
    :deep(.price-container) {
      .current-price,
      .orig-price,
      .orig-price .strikethrough {
        font-size: 0.875rem;
        line-height: 1.5;
      }
    }
    :deep(.image-wrapper) {
      overflow: hidden;
      border-radius: 4px;
    }
    :deep(.image) {
      background-color: $color-neutral-cool-50;
      transition: transform 0.2s;
      width: 100%;
      height: auto;
      border-radius: 4px;
      &:hover {
        transform: scale(1.1);
      }
      position: relative;
    }
    &:hover {
      :deep(.image) {
        transform: scale(1.1);
      }
    }
    @include local-mixins.tablet_and_mobile {
      .product-title {
        font-size: 1rem;
      }
      .product-details {
        margin-top: 8px;
      }
    }
  }
  &--modal {
    border: 1px solid $color-neutral-cool-100;
    border-radius: 4px;
    padding: 0.5rem;
    margin: 0 0 #{local-functions.rem-calc(20)} 0;
    background-color: $color-neutral-white;
    .dy-product-card {
      display: flex;
      gap: 20px;
      .product-title {
        font-size: 1rem;
      }
      .product-details {
        display: flex;
        flex-direction: column;
        margin-top: 0;
        .price-container {
          order: 2;
          margin-bottom: 0;
        }
        .yotpo.bottomLine {
          order: 3;
        }
      }
      :deep(.image) {
        max-height: #{local-functions.rem-calc(80)};
        max-width: #{local-functions.rem-calc(80)};
      }
      .price-container {
        .current-price,
        .orig-price,
        .orig-price .strikethrough {
          font-size: 0.875rem;
          line-height: 1.5;
        }
        .orig-price {
          margin-left: 5px;
        }
        .savings-cta {
          display: none;
        }
      }
    }
    .add-to-cart-wrapper {
      margin-top: 0;
      :deep(.btn) {
        margin-left: 100px;
        width: calc(100% - 100px);
      }
    }
  }
}

@include local-mixins.tablet_and_mobile {
  .dy-card-container {
    position: relative;
    z-index: 0;
    &:last-child {
      padding-right: 0.75rem;
    }
  }
}
</style>
